import { Component, OnInit } from '@angular/core';
import { MainService } from '../../providers/mainService';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CeiboShare } from 'ng2-social-share'

declare var $:any
@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {

  constructor(
    private service: MainService
  ) { }
  public link: string = "https://res.cloudinary.com/singhanurag400/image/upload/v1612534993/cwedcyzqeyiekitt674u.jpg"
  ngOnInit() {

  }

 
}
