import { Component, OnInit } from '@angular/core';
import { MainService } from '../../providers/mainService';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '../../../../node_modules/@angular/forms';
import { PARAMETERS } from '@angular/core/src/util/decorators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';

declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  total: any
  dashboardForm: FormGroup;
  pageNo: any = 1;
  collection = [];
  customer: any = [];
  id: any = '';
  srNo: number;
  srNo1: number;
  paginationData: any = {};
  status: any;
  obj: any = {};
  modified: any = []
  result: any;
  realStatus: string = "BLOCK";
  token: any;
  param: any = {};
  stat: any;
  _id: any;

  constructor(private router: Router,
    public service: MainService,
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    public route: ActivatedRoute) {

    this.dashboardForm = this.fb.group({
      'search': [''],
      'sort': [''],
      'pageNumber': ['']
    })

    for (let i = 1; i <= 10; i++) {
      this.collection.push(`item ${i}`);
    }
  }
  ngOnInit() {
    this.spinner.show()
  

    this.route.params.subscribe(params => {

      // console.log('params=>',(params));
      this.param = params;

      this.search()


    });

    // this.search()

  }

  view(l) {
    // console.log("view data >>",l)
    localStorage.setItem('_id of customer', this.customer[l]._id);
    this.router.navigate(['/userDetails']);
  }



  userStatus(item) {
    // console.log("item to block and activate",item)

    this.status = item.status;


    if (!this.status) {

      this.realStatus = "ACTIVE";
      // console.log("anurag",this.realStatus)
    }

    let delData = { '_id': item._id, status: this.realStatus }

    console.log("by toggle", delData)
    // this.service.postApi(`users/updateUser`, delData, 1).subscribe(response => {
    //   if (response['responseCode'] == 200) {
    //     // this. search();
    //   }
    //   else {
    //     this.service.toastErr(response['responseMessage'])
    //   }
    // }, error => {
    //   this.service.toastErr('Something Went Wrong');
    // })

  }


  edit(l) {
    localStorage.setItem('_id of customer', this.customer[l]._id);
    this.router.navigate(['./editUserDetail']);
  }

  userdel(l) {
    localStorage.setItem('_id of customer', this.customer[l]._id);
    $('#delete').modal('show');
  }
  delete(l) {
    this.spinner.show()
   
    this.service.getApi(`users/deleteUser?_id=${localStorage.getItem('_id of customer')}`, 1)
    .subscribe(response => {
      if (response['responseCode'] == 200) {
        localStorage.removeItem('_id of customer')
        this.search()
      }
      else {
        this.service.toastErr(response['responseMessage'])
      }
    
      this.spinner.hide()
    }, error => {
    
      this.spinner.hide()
      this.service.toastErr('Something Went Wrong');
    })
    $('#delete').modal('hide');

  }


  //    change status...

  changeStatus(_id, status) {
    console.log("_id", _id)
    this.stat = status
    this._id = _id
    $('#block').modal('show');








  }

  blockUnblock() {
    this.spinner.show()
  
    let delData = { '_id': this._id, status: this.stat }
    console.log("delData", delData)
    $('#block').modal('hide');
    this.service.postApi(`users/updateUser`, delData, 1).subscribe(response => {
      console.log("change status", response)
      if (response['responseCode'] == 200) {
        this.service.showSuccess(response[`responseMessage`])

        this.search();
      }
      else {
        this.service.toastErr(response['responseMessage'])
      }
     
      this.spinner.hide()
    }, error => {
   
      this.spinner.hide()
      this.service.toastErr('Something Went Wrong');
    })

  }

  //................



  search() {
    this.spinner.show()
  
   
    let postData = {
      "search": this.dashboardForm.value.search,
      "status": this.dashboardForm.value.sort,
      "page": this.pageNo,
      "limit": 10
    }
    if (this.param["type"] == "player") {
      postData["role"] = "PLAYER";
    }


    for (let val in postData) {
      if (postData[val] == '') {
        delete postData[val]
      }
    }
    this.service.postApi(`users/userList`, postData, 1).subscribe(response => {
      console.log("users/userList>>>", response)
      if (response['responseCode'] == 200) {


        this.modified = this.customer = response['result']["docs"];
        console.log("data customer>>>", this.modified)

        // for (let i = 0; i < this.modified.length; i++) {
        //   if (this.modified[i].status == 'ACTIVE') {
        //     this.modified[i].status = true

        //   }

        // else if (this.modified[i].status == 'BLOCK') {
        //   this.modified[i].status = false

        // }
        // }
        console.log("data modified true false >>>", this.modified)
        this.paginationData = response['result']
        this.srNo = (this.pageNo - 1) * this.paginationData.limit;

      }

      else {

        this.paginationData = { total: 0, limit: 10 }
        this.modified = [];
      }
     
      this.spinner.hide()
    }, error => {
     
      this.spinner.hide()
      this.service.toastErr(`Something went wrong`)
    })



  }
  filter() {
    this.pageNo = 1;
    this.search()
  }
  changePage(page) {
    this.pageNo = page
    this.search()
  }
  createOrg(){
    
  }
  print(){
    let printContents = document.getElementById('tablerecords').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload()
  }

  exportToCSV(){
    var data = [
      {
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        gender: 'Gender',
        status: 'Status' ,
        role:'Role',
        subscription:'Subscription'
      }
    ];
    for(let i=0; i < this.modified.length; i++) {
      data.push({
        name: this.modified[i].firstName+ " "+ this.modified[i].lastName,
        email: this.modified[i].email,
        phone: this.modified[i].mobileNumber || '- - -',
        gender: this.modified[i].gender,
        status: this.modified[i].status,
        role:this.modified[i].role[0],
        subscription: this.modified[i].subscription,

      })
      if(i+1 == this.modified.length) 
        new Angular5Csv(data, 'management');
    }
    console.log("Data----->  ",data);
  }

}

