import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from 'src/app/providers/mainService';

@Component({
  selector: 'app-platform-edit',
  templateUrl: './platform-edit.component.html',
  styleUrls: ['./platform-edit.component.css']
})
export class PlatformEditComponent implements OnInit {
  form: FormGroup;
  faqList: any;
  faqId: any;
  ques: any;
  ans : any;
  regex ="^[^\s]+[-a-zA-Z\s]+([-a-zA-Z]+)*$"
  constructor(private activatedroute: ActivatedRoute,private router : Router, public service:MainService, private spinner: NgxSpinnerService) {
    this.activatedroute.queryParams.subscribe((res) => {
      this.faqId = res.itemId;

    });
  }

  ngOnInit() {

    this.form = new FormGroup({
      "question": new FormControl('',[ Validators.required]),
      "answer": new FormControl('',[ Validators.required]),
    });
    this.viewFaq();
  }

// view
viewFaq(){
  let url = `static/viewPlatform?_id=${this.faqId}`

     this.spinner.show() 
    this.service.getApi(url,1).subscribe((res:any) => {
      if(res.response_code == 200){
        this.form.patchValue({
          "question": res.result.title,
          "answer": res.result.description
        })
        this.service.showSuccess(res.response_message)
           this.spinner.hide();
        // this.router.navigate(['/faq'])
      }
      else{
        this.service.toastErr(res.response_message)
           this.spinner.hide();
      }
    })  
}


  updateFaq(){
    let url = `static/editPlatform?_id=${this.faqId}`
    const data = {
  //  "faqId": this.faqId,
   "title": this.form.value.question,
   "description" : this.form.value.answer,
   "userid" : localStorage.getItem('adminId')
    }
    this.spinner.show() 
    this.service.postApi(url,data,1).subscribe((res:any) => {
      if(res.response_code == 200){
        this.service.showSuccess(res.response_message)
           this.spinner.hide();
        this.router.navigate(['/platform-list'])
      }
      else{
        this.service.toastErr(res.response_message)
           this.spinner.hide();
      }
    })
  }

}
