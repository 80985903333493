import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../providers/mainService';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any
@Component({
  selector: 'app-venue-details',
  templateUrl: './venue-details.component.html',
  styleUrls: ['./venue-details.component.css']
})
export class VenueDetailsComponent implements OnInit {
  id: any
  venueData: any
  imageUrl: string
  pageNo: number = 1
  xx: any = [{ starsCount: '' }]
  limit: any;
  page: any;
  srNo: number;
  currentPage: number
  totalItems: number
  userRating: any
  avgVenue: any
  flag: number = 0

  total: any;
  venueLength: number = 0;

  constructor(
    private service: MainService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
  ) {
    this.route.params.subscribe(params => {
      console.log(params)
      this.id = params
    })
  }

  ngOnInit() {
    this.xx.starsCount = 4
    this.getData()
    $('#rating_user').hide();
  }

  show() {
    $('#rating_user').fadeIn('slow');
    this.getList()
  }

  getData() {
    let apiData = {
      "venueId": this.id.id
    }
    this.spinner.show()
    this.service.postApi('admin/particularVenue', apiData, 1).subscribe(success => {
      this.spinner.hide();
      console.log("success", success)
      if (success.responseCode == 200) {
        this.venueData = success.result[0]
        if (this.venueData.average != 0) {
          this.flag = 1
          this.avgVenue = this.venueData.average
        }
        if (success.result[0].images[0]) {
          this.imageUrl = success.result[0].images[0]
        } else {
          this.imageUrl = 'assets/img/default_profile_image.png'
        }
        console.log("success..")
      } else {
      }
    }, error => {
      this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }

  changePage(page) {
    this.pageNo = page
    console.log("tthis.pageNio", this.pageNo)
    this.getList()
  }

  getList() {
    let apiData = {
      "page": this.pageNo,
      "limit": 10,
      venueId: this.id.id
    }
    console.log(apiData)
    this.spinner.show()
    this.service.postApi('admin/getRatingOfAllUserOnAVenue', apiData, 1).subscribe(success => {
      this.spinner.hide();
      if (success.responseCode == 200) {
        var length = success.result.docs
        if (length != 0) {
          this.userRating = success.result.docs
          this.venueLength = this.userRating.length
          this.total = success.result.total
          this.limit = success.result.limit
          this.page = success.result.page
          this.srNo = (this.pageNo - 1) * this.limit;
        }
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
      this.spinner.hide();
      this.service.toastErr("Somethng went wrong")
    })
  }
}
