import { Component, OnInit } from '@angular/core';
import { MainService } from '../../providers/mainService';
import { Router } from '../../../../node_modules/@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-view-business-detail',
  templateUrl: './view-business-detail.component.html',
  styleUrls: ['./view-business-detail.component.css']
})
export class ViewBusinessDetailComponent implements OnInit {
  business: any;
  Id: any = '';
  imageUrl: any;
  DOB: string;
  constructor(private service: MainService,
    private spinner: NgxSpinnerService,
    private route: Router) { }

  ngOnInit() {
    this.Id = localStorage.getItem('_id of business')
    this.viewParticularBusiness()
  }
  //==========================================================VIEW PARTICULAR CUSTOMER API ====================================================================
  viewParticularBusiness() {
    this.spinner.show()
    this.service.getApi(`admin/viewDetail/${localStorage.getItem('_id of business')}`, 1).subscribe(response => {
      this.spinner.hide();
      if (response['response_code'] == 200) {
        this.business = response[`result`]
        if (response['result'].profilePic) {
          this.imageUrl = this.business.profilePic
        } else {
          this.imageUrl = 'assets/img/default_profile_image.png'
        }
      } else {
        this.service.toastErr(response['response_message'])
      }
    }, error => {
      this.spinner.hide();
      this.service.toastErr('Something Went Wrong')
    })
  }

  edit() {
    this.route.navigate(['/editBusinessDetail'])
  }

  cancel() {
    this.route.navigate(['/business-management'])
  }
}
