import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from '../../../providers/mainService';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.css']
})
export class SmsComponent implements OnInit {
  SMSForm: any
  paramsdata: any
  responseData: any;
  type: string;
  constructor(
    private service: MainService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  async ngOnInit() {
    await this.form()        // form control
    await this.getType()    //params type
    await this.getSMSData()   //  get SMS Data
  }

  getType() {
    this.route.params.subscribe(parms => {
      var checkType = parms.type
      if (checkType == 'SMS') {
        this.paramsdata = "sms"
        this.type="SMS"
      } else if (checkType == 'SMTP') {
        this.paramsdata = "smtp"
        this.type="SMTP"
      } else if (checkType == 'paymentGateway') {
        this.paramsdata = "paymentGateway"
        this.type="payment Gateway"
      } else if (checkType == 'registration') {
        this.paramsdata = "RegistrationOrganizer"
        this.type="Registration Organizer"
      }
    })
  }

  form() {
    this.SMSForm = new FormGroup({
     sms: new FormControl('Auth_token', [Validators.required]),
      sms1: new FormControl('S_Id', [Validators.required]),
      sms2: new FormControl('Number', [Validators.required]),
      smtp: new FormControl('SMTP_UserName', [Validators.required]),
      smtp1: new FormControl('SMTP_Password', [Validators.required]),
      paymentGateway: new FormControl('paymentGateway', [Validators.required]),
    })
  }

  getSMSData() {
    let apiData = {
      type: this.paramsdata,
      search: ''
    }
    console.log("api", apiData)
    this.service.postApi('admin/getALL', apiData, 1).subscribe(success => {
      console.log(success)
      if (success.responseCode == 201) {
        console.log("rtrtrtrtrtrtrtrtrtrtr",success.result.docs[0].type)
        if (success.result.docs.length > 0) {
          console.log("rtrtrtrtrtrtrtrtrtrtr",success.result.docs[0].type)
          if (success.result.docs[0].type == 'sms') {
            this.responseData = success.result.docs[0]
            console.log("this.responseData", this.responseData)
            this.SMSForm.controls['sms'].setValue(this.responseData.auth_token)
            this.SMSForm.controls['sms1'].setValue(this.responseData.sid)
            this.SMSForm.controls['sms2'].setValue(this.responseData.number)
          } else if (success.result.docs[0].type == 'smtp') {
            this.responseData = success.result.docs[0]
            console.log("this.responseData", this.responseData)
            this.SMSForm.controls['smtp'].setValue(this.responseData.smtpUsername)
            this.SMSForm.controls['smtp1'].setValue(this.responseData.smtpPassword)
           

          } else if (success.result.docs[0].type == 'paymentGateway') {
            this.responseData = success.result.docs[0]
            console.log("this.responseData", this.responseData)
            this.SMSForm.controls['paymentGateway'].setValue(this.responseData.merchantId)
          }
        }


      } else {
        this.service.toastErr(success.responseCode)
      }
    })
  }
}
