import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from 'src/app/providers/mainService';

@Component({
  selector: 'app-platform-list',
  templateUrl: './platform-list.component.html',
  styleUrls: ['./platform-list.component.css']
})
export class PlatformListComponent implements OnInit {
  faq : any = [];
  faqId:any
  viewPlatformList: void;
  constructor(private service: MainService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router:Router) { }

  ngOnInit() {
    this.viewplatform()
  }
   
    // -------view faq-------
  viewplatform(){
    let url = `static/listPlatform`
    this.spinner.show() 
    this.spinner.show()
    this.service.getApi(url,1).subscribe((response:any) =>{
      if(response.response_code == 200){
        this.viewPlatformList=response.result
        this.spinner.hide();
        this.service.showSuccess(response['response_message'])
      }
      else{
        this.spinner.hide();
        this.service.toastErr(response['response_message'])
      }
    },(err)=>{
      this.spinner.hide();
      this.service.toastErr(err['response_message'])
    })
  }
  
  
  navigateEditfaq(id){
    this.router.navigate(["/platform-edit"],{queryParams:{itemId:id}})
  }

}
