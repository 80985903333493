import { Component, OnInit } from '@angular/core';
import { MainService } from '../../providers/mainService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.css']
})
export class SubscriptionListComponent implements OnInit {
  subscriptionData:any

 
  constructor(
    private service:MainService,
    private Router: Router
  ) { }

  ngOnInit() {
  
  }
  
 

 

}
