import { Component } from '@angular/core';
import { MainService } from './providers/mainService';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  loginstatus: boolean;
  currUrl: string;
  constructor(public service: MainService, private router: Router) {
    this.router.events.subscribe(x => {
     if(x instanceof NavigationEnd) {
        this.currUrl = x.url.split('/')[1];
        this.currUrl = (this.currUrl.indexOf('?') != -1 ) ? this.currUrl.substr(0, this.currUrl.indexOf('?')) : this.currUrl
        console.log("current url ==>> "+this.currUrl);
        if(localStorage.getItem(`adminId`) != null) {
        if((this.currUrl ==`login` ||this.currUrl == `forgotPassword` ||this.currUrl == `reset-password`)) {
        this.router.navigate([`/dashboard/organizer`])
        }
        } else {
        if(!(this.currUrl == `login` ||this.currUrl == `reset-password`|| this.currUrl == `forgotPassword`)) {
        this.router.navigate([`/login`])
        }
        }
      }
    });
  }
}