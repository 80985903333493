import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../providers/mainService';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-membership-management-details',
  templateUrl: './membership-management-details.component.html',
  styleUrls: ['./membership-management-details.component.css']
})
export class MembershipManagementDetailsComponent implements OnInit {
  id: any;
  competitionData: any;
  imageUrl: any;

  constructor(
    private service: MainService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    this.route.params.subscribe(params => {
      this.id = params.id
    });
  }
  ngOnInit() {
    this.viewParticularCustomer()
  }

  viewParticularCustomer() {
    let apiData = {
      "membershipId": this.id
    }
    this.spinner.show()
    this.service.postApi('admin/particularMembership', apiData, 1).subscribe(success => {
      this.spinner.hide();
      console.log("success==========>>>>>>", success)
      if (success.responseCode == 200) {
        this.competitionData = success.result[0]
        if (success.result[0].imageURL) {
          this.imageUrl = success.result[0].imageURL
        } else {
          this.imageUrl = 'assets/img/default_profile_image.png'
        }
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
      this.spinner.hide();
      this.service.toastErr("Somethng went wrong")
    })
  }
}
