import { Component, OnInit } from '@angular/core';
import { MainService } from '../../providers/mainService';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $:any
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  chatData: any;
  chatingForm: FormGroup
  chatList: any;
  conversationList: any;
  adminId: any;
  userId: any;
  flag: any = 0
  particularUserDetails: any;
  profilePic: any;
  RecentChatList:any
  constructor(
    private service: MainService
  ) { }

  ngOnInit() {
    this.form()              // formValidation
    this.getChatList()      // getUserList For Chat

    // this.getRecentUserList()      // getRecentUserList of Chat
  }
  form() {
    this.chatingForm = new FormGroup({
      sendMessage: new FormControl('', [Validators.required])
    })
  }
  //...................getRecentUserList................

  getRecentUserList() {
    // recentAdminChats
    this.service.getApi((`admin/recentAdminChats`), 1).subscribe(success => {
      console.log("success=====>>getList", success)
      if (success.responseCode == 200) {
        this.RecentChatList = success.result
     
       
       for(let i=0;i<   this.RecentChatList.length;i++){
       var length= this.RecentChatList[i].message.length-1
       console.log("length",length)
       console.log("last message",this.RecentChatList[i].message[length].message)

       this.RecentChatList[i]["lastMessage"]={"message":this.RecentChatList[i].message[length].message,createdAt:this.RecentChatList[i].message[length].createdAt}
console.log("   this.RecentChatList",   this.RecentChatList)
       }
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
      this.service.toastErr("Something went wrong")
    })

  }

  //..........................getList Function..............................................................
  getChatList() {
    let apiData = {
      "role": "",
      "search": "",
      "page": '',
      "limit": 1000
    }
    this.service.postApi((`users/userList`), apiData, 1).subscribe(success => {
      // console.log("success=====>>getList", success)
      if (success.responseCode == 200) {
        this.chatList = success.result.docs
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
      this.service.toastErr("Something went wrong")
    })
  }
  particularUser(data: any, type: any) {
    
    if (type == 'totalUser') {
      console.log("totalUSer==>",data)
      if (data.image) {
        this.profilePic = data.image
      } else {
        this.profilePic = 'assets/img/profile.jpg'
      }
    
      this.particularUserDetails = data
      console.log("===>>",data._id)
    } else if (type == 'recentChatUser') {
      console.log("recentchat user==>",data)
      this.particularUserDetails=data.playerId

    
    }

    let request = {
      "playerId": this.particularUserDetails._id,
      "organizerId": localStorage.getItem('adminId')
    }
    this.conversationTwoUser(request);
  }

  changeStatus(){
    let apiRequest={
      "playerId": this.particularUserDetails._id,
      "organizerId": localStorage.getItem('adminId')
    }
    this.service.postApi(`admin/readMessageOrganizer`, apiRequest, 1).subscribe(success => {
      console.log("admin/readMessageOrganizer=====>>getList", success)
      if (success.responseCode == 200) {
        this.getRecentUserList()
      } else {
       
      }
    }, error => {
      this.service.toastErr("Something went wrong")
    })

  }
  //........................ Admin and user Conversation function,............................
  conversationTwoUser(apiRequest: any) {
    this.service.postApi(`admin/chatAdminAndUser`, apiRequest, 1).subscribe(success => {
      console.log("Conversation=====>>getList", success)
      if (success.responseCode == 200) {
        this.changeStatus();
        this.flag = 1                           //show  chat screeen 
        if (success.result.docs.length > 0) {
          this.conversationList = success.result.docs[0].message
          this.adminId = success.result.docs[0].organizerId._id
          this.userId = success.result.docs[0].playerId
        } else {
          this.conversationList = []
          this.adminId = ''
          this.userId = ''
        }
        setTimeout(function () {
          $('#chatList').scrollTop($('#chatList')[0].scrollHeight);
          }, 200); 
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
      this.service.toastErr("Something went wrong")
    })
  }
  //..............................send Message Function......................................
  sendMessage(data: any) {
    console.log("Send Message", data)
    if (this.chatingForm.invalid) {
      this.service.toastErr("Please type some message")
      return
    }
    let apiRequest = {
      "playerId": this.particularUserDetails._id,   // send by admin
      "organizerId": localStorage.getItem('adminId'),
      "message": {
        "message": data.sendMessage,
        playerRead:false,
        organizerRead:true,
        "senderId": localStorage.getItem('adminId') 
      }
    }
    this.chatingForm.controls['sendMessage'].setValue('')
    console.log("apiRequest", apiRequest)
    this.service.postApi(`admin/chatting`, apiRequest, 1).subscribe(success => {
      console.log("send message=====>>success ", success)
      if (success.responseCode == 200) {
        let request = {
          "playerId": this.particularUserDetails._id,
          "organizerId": localStorage.getItem('adminId')
        }
        this.conversationTwoUser(request)
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
      this.service.toastErr("Something went wrong")
    })
  }
  //......................... End send Message Function..................................

  hideChatScreen(){
    this.flag=0
  }
}
