import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from 'src/app/providers/mainService';

@Component({
  selector: 'app-service-view',
  templateUrl: './service-view.component.html',
  styleUrls: ['./service-view.component.css']
})
export class ServiceViewComponent implements OnInit {
  id: any;
  serviceDetail: any;

  constructor(private service: MainService,private route: ActivatedRoute,private ng2ImgMaxService: Ng2ImgMaxService,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.id = param.id
  })
  this.getServiceDetails()
}

getServiceDetails() {
  let apiData = {
    _id: this.id
  }
  this.spinner.show()
     this.service.postApi(`admin/particularList`, apiData, 1).subscribe(success => {
            this.spinner.hide();
    if (success.responseCode == 200) {
      this.serviceDetail = success.result
    } else {
      this.spinner.hide();
      this.service.toastErr(success.responseMessage)
    }
  }, error => {
        this.spinner.hide();
    this.service.toastErr("Something went wrong")
  })
}
}
