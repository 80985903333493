import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../providers/mainService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-feature-list',
  templateUrl: './edit-feature-list.component.html',
  styleUrls: ['./edit-feature-list.component.css']
})
export class EditFeatureListComponent implements OnInit {
  VideoData:any
  constructor(
    private service:MainService,
    private router:Router,

  ) { }

  ngOnInit() {
this.getList()
  }

  getList(){
    this.service.getApi(`admin/featureListing`,1).subscribe(success=>{
      if(success.responseCode==200){
        this.VideoData=success.result

      } else{
        this.service.toastErr(success.responseMessage)

      }
      console.log(success)
    },error=>{
      this.service.toastErr("Something went wrong")
    })
  }

}
