import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from 'src/app/providers/mainService';

@Component({
  selector: 'app-platform-add',
  templateUrl: './platform-add.component.html',
  styleUrls: ['./platform-add.component.css']
})
export class PlatformAddComponent implements OnInit {
  platformForm:FormGroup
  platformId:any
  constructor(private router:Router, public service: MainService, private spinner:NgxSpinnerService) { }

  ngOnInit() {
    this.platformForm= new FormGroup({
      'title':new FormControl('',[Validators.required]),
      'description':new FormControl('',[Validators.required])
    })

  } 
  addPlatform(){
    let url ="static/addPlatform"
    let apiReqData={
      'title': this.platformForm.value.title,
      'description':this.platformForm.value.description
    }
    this.spinner.show()
    this.service.postApi(url,apiReqData,1).subscribe((res)=>{
    console.log(res);
    if(res.response_code==200){
      this.spinner.hide()
      this.service.showSuccess(res.response_message)
      this.router.navigate(["/platform-list"])
    }
    else{
      this.spinner.hide()
      this.service.toastErr(res.response_message)
    }
    },(err)=>{
      this.spinner.hide()
      this.service.toastErr(err.response_message)
    })
  }

}
