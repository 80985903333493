import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MainService } from '../../../providers/mainService';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-competition-management-view',
  templateUrl: './competition-management-view.component.html',
  styleUrls: ['./competition-management-view.component.css']
})
export class CompetitionManagementViewComponent implements OnInit {
  editForm: FormGroup
  customer: any;
  Id: any = '';
  image: string | ArrayBuffer;
  codeArray: any = [];
  id: any;
  competitionData: any;
  constructor(
    private service: MainService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private router: ActivatedRoute
  ) {
    this.editForm = formBuilder.group({
      firstName: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z ]{2,40}$/), Validators.minLength(2)])],
      lastName: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z ]{2,40}$/), Validators.minLength(2)])],
      gender: ['', Validators.compose([])],
      countryCode: ['', Validators.compose([Validators.required])],
      mobileNumber: ['', Validators.compose([Validators.required, Validators.pattern(/^[1-9][0-9]{6,13}$/)])],
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^[A-Z0-9_-]+([\.][A-Z0-9_]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,3})+$/i), Validators.maxLength(50)])],
    })
    this.router.params.subscribe(params => {
      this.id = params.id
    });
  }

  ngOnInit() {
    this.spinner.show()
    this.Id = localStorage.getItem('_id of customer')
    this.viewParticularCustomer();
  }
  //==========================================================VIEW PARTICULAR cpmpetition API ====================================================================
  viewParticularCustomer() {
    let apiData = {
      "competitionId": this.id
    }
    this.service.postApi('admin/particularCompetition', apiData, 1).subscribe(success => {
      if (success.responseCode == 200) {
        this.competitionData = success.result[0]
        console.log(this.competitionData)
      } else {
        this.service.toastErr(success.responseMessage)
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.service.toastErr("Somethng went wrong")
    })
  }
}