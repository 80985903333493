import { Component, OnInit } from '@angular/core';
import { MainService } from '../../providers/mainService';
import { NgModule } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  
  forgotForm : any;
  constructor(private router: Router,private formBuilder:FormBuilder,public service:MainService) { }

  ngOnInit() {

    this.forgotForm=this.formBuilder.group({
      'email': ['', Validators.compose([Validators.required, Validators.pattern(/^[A-Z0-9_-]+([\.][A-Z0-9_]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,3})+$/i), Validators.maxLength(50)])],
    });

    $(function () {
      $('#email,#pass').on('keypress', function (e) {
        if (e.which == 32)
          return true;
      });
    });
  }
  forgot(val){
    let forgotData={
      'email':val.email
    }
    this.service.postApi('users/sendLink',forgotData,0).subscribe(response=>{
    if(response['responseCode']==200) {
      this.service.showSuccess(response['responseMessage'])
      this.router.navigate(['/login'])

    }
    else{
      this.service.toastErr(response['responseMessage'])
    }
    },error=>{
      this.service.toastErr(error)
    })
  }

}
