import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MainService } from '../../providers/mainService';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-news-later-list',
  templateUrl: './news-later-list.component.html',
  styleUrls: ['./news-later-list.component.css']
})
export class NewsLaterListComponent implements OnInit {
  codeCountryList: any
  competitionForm: any
  VideoData: any = [{}]
  selectedAll: any
  playerList: any;
  organizerList: any;
  searchData: any = { role: '', player: '', search: '', organizer: '' };
  filePath: any;
  fd: any;
  userForSendMail: any;
  constructor(
    private service: MainService,
     private spinner: NgxSpinnerService
  ) {
   
  }

  async ngOnInit() {
       this.form()
    await this.getList();
    await this.getPlayer();
    await this.getOrganizer();
  }
  form() {
    this.competitionForm = new FormGroup({
      player: new FormControl(''),
      search: new FormControl(''),
      role: new FormControl(''),
      organizer: new FormControl('')
    })
  }

  search(data) {
    console.log("DATA=========...>>>", data)
    this.searchData = data
    this.getList()
  }

  getPlayer() {
    this.fd = new FormData();
    let apiData = {
      "role": 'PLAYER',
      "search": "",
      "orgId": "",
      "_id": ""
    }
    this.spinner.show()
       this.service.postApi(`admin/newsletterRole`, apiData, 1).subscribe(success => {
              this.spinner.hide();
      if (success.responseCode == 200) {
        this.playerList = success.result.docs
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
           this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }

  getOrganizer() {
    let apiData = {
      "role": 'ORGANIZER',
      "search": "",
      "orgId": "",
      "_id": ""
    }
    this.spinner.show()
      this.service.postApi(`admin/newsletterRole`, apiData, 1).subscribe(success => {
              this.spinner.hide();
      if (success.responseCode == 200) {
        this.organizerList = success.result.docs
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
          this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }

  getList() {
    let apiData = {
      "role": this.searchData.role,
      "search": "",
      "orgId": "",
      "_id": ""
    }
    this.spinner.show()
        this.service.postApi(`admin/newsletterRole`, apiData, 1).subscribe(success => {
           this.spinner.hide();
          if (success.responseCode == 200) {
       
        let result: any = []
        result = success.result.docs
        for (let i = 0; i < result.length; i++) {
          result[i]['selected'] = false
        }
        this.VideoData = result
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
          this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
  //....................... check selected e-mail send  Function.......
  send() {
    if(!this.filePath){
      return this.service.toastErr("Please select attachment.")
    }
    let selectEmail = []
    for (let i = 0; i < this.VideoData.length; i++) {
      if (this.VideoData[i].selected) {
        selectEmail.push(this.VideoData[i].email)
      }
    }
    console.log('selectEmail===========>>>>>.', selectEmail)
    if(selectEmail.length==0){
      this.service.toastErr("Select atleast one user.")
      return
    }
    this.userForSendMail = selectEmail
    console.log(this.userForSendMail)
    // this.fd = new FormData();
    this.fd.append('mailList', this.userForSendMail)
    // this.fd.append('attatment', )
    this.spinner.show()
       this.service.postApiMessage(`admin/sendMailAll`, this.fd, 1).subscribe(success => {
              this.spinner.hide();
      if (success.responseCode == 200) {
        this.service.showSuccess(success.responseMessage)
                this.getList();
        this.selectedAll=''
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
        this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
  //............................Select All Function ................
  selectAll() {
    for (var i = 0; i < this.VideoData.length; i++) {
      this.VideoData[i].selected = this.selectedAll;
    }
  }
  //..............................single select disselect..............
  checkIfAllSelected() {
    this.selectedAll = this.VideoData.every(function (item: any) {
      return item.selected == true;
    })
  }
  //......................Particular user send mail funcion....................
  sendMail(data) {
    if(!this.filePath){
      return this.service.toastErr("Please select attachment.")
    }
    let list = []
    list.push(data)
    // this.fd = new FormData();
    this.fd.append('mailList', list)
    this.spinner.show()
       this.service.postApiMessage(`admin/sendMailAll`, this.fd, 1).subscribe(success => {
        this.spinner.hide();
      if (success.responseCode == 200) {
        this.service.showSuccess(success.responseMessage)
        this.getList();
        this.selectedAll=''
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
       this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
  //...............................delete user Function...................
  delete(data) {
    console.log("delete==============>>>>>", data)
     let apidata={
      userId:data
     }
     this.spinner.show()
       this.service.postApiMessage(`admin/deletenewsletter`,apidata, 1).subscribe(success => {
               this.spinner.hide();
      console.log("success=====>>delete",success)
      if (success.responseCode == 200) {

        this.service.showSuccess(success.responseMessage)
        this.getList();
        this.selectedAll=''
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
          this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
//................File Select for Form Data....................................
  onUploadChange(evt: any) {
    const file = evt.target.files[0];
    console.log("Filename--->> ", file.name);
    this.forgot(file);
  }
  forgot(image) {
    if (image) {
      this.filePath = image
      this.fd.append('attachement', image);
    } else {
      this.fd.append('attachement', '');
    }
    console.log("ImageRequest--->>> ", this.fd)
  }
}
