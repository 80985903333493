import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MainService } from '../../../providers/mainService';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  paramsdata: string;
  type: string;
  addMore: any
  noOfAddressField=1
  constructor(
    private service: MainService,
    private route: ActivatedRoute,
    private router: Router,
    private _FB: FormBuilder
  ) { }

  ngOnInit() {
    this.getType();
    this.addMore = new FormGroup({
     
    
      medi: this._FB.array([
        this.addAnotherField()
      ])
    })
  }

  addAnotherField(){
    return this._FB.group({
      labelName: new FormControl('', [Validators.required,Validators.pattern('[A-Za-z][A-Za-z \\s]*$')])
    });
  }

  
  addNewInputField(ind): void {
    const control = <FormArray>this.addMore.controls.medi;
    control.push(this.addAnotherField());
    this.noOfAddressField++;
  }

  removeInputField(i: number): void {
    const control = <FormArray>this.addMore.controls.medi;
    control.removeAt(i);
    this.noOfAddressField--;
  }


  submitFunc(data: any) {
    console.log("FormArray=========>>>>>", data)
  }



  getType() {
    this.route.params.subscribe(parms => {
      var checkType = parms.type
      console.log("checkType", checkType)
      if (checkType == 'registration') {
        this.paramsdata = "RegistrationOrganizer"
        this.type = "Registration Organizer"
      }
    })
  }

  getSMSData() {
    let apiData = {
      type: this.paramsdata,
      search: ''
    }
    console.log("api", apiData)
    this.service.postApi('admin/getALL', apiData, 1).subscribe(success => {
      console.log(success)
      if (success.responseCode == 201) {
      } else {
        this.service.toastErr(success.responseCode)
      }
    })
  }
}
