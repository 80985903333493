import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from 'src/app/providers/mainService';

@Component({
  selector: 'app-home-page-content-management',
  templateUrl: './home-page-content-management.component.html',
  styleUrls: ['./home-page-content-management.component.css']
})
export class HomePageContentManagementComponent implements OnInit {
  logoDetail: any;
  logoid: any;
  bannerid: any;
  bannerDetail: any;


  constructor(private service: MainService,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.getLogo()
    this.getbanner()
  }

  getLogo() {
    this.spinner.show()
      this.service.getApi('admin/logoListing', 1).subscribe(success => {
      this.spinner.hide();
      if(success.responseCode==200){
      this.logoDetail=success.result[0]
      this.logoid=success.result[0]._id
      } else{
        this.spinner.hide();
        this.service.toastErr(success.responseMessage)

      }
    }, error => {
            this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }
  getbanner() {
    this.spinner.show()
      this.service.getApi('admin/bannerListing', 1).subscribe(success => {
      this.spinner.hide();
      if(success.responseCode==200){
      this.bannerDetail=success.result[0]
      this.bannerid=success.result[0]._id
      } else{
        this.spinner.hide();
        this.service.toastErr(success.responseMessage)

      }
    }, error => {
            this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }

}
