import { Component, OnInit } from '@angular/core';
import { MainService } from '../../providers/mainService';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '../../../../node_modules/@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  signupForm: FormGroup;
  countryList:any =[]
  constructor(private router: Router,
    public service: MainService,
    private spinner: NgxSpinnerService,
    public route: ActivatedRoute,
    private fb: FormBuilder) {}

  ngOnInit() {
    this.getPlatformData()
    this.contactForm()
    this.getCountryList()
    this.getContact()

  }
   getCountryList() {
    this.service.getApi(`users/code`, 0).subscribe(response => {
      if(response.responseCode == 200) {
        this.countryList = response.result
      }
    })
  }

 contactForm(){
  this.signupForm = this.fb.group({
    'address': ['', Validators.compose([ Validators.required, Validators.minLength(2),Validators.maxLength(200)])],
    'phoneNo': ['', Validators.compose([ Validators.required, Validators.minLength(15)])],
    'otheremail': ['', Validators.compose([Validators.pattern(/^[A-Z0-9_-]+([\.][A-Z0-9_]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,15})+$/i) ])],
    'email': ['', Validators.compose([ Validators.required, Validators.pattern(/^[A-Z0-9_-]+([\.][A-Z0-9_]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,15})+$/i) ])],
    'mobCode': ['', Validators.required],
    'mobile': [ '', Validators.compose([Validators.required, Validators.pattern(/^[1-9][0-9]{7,15}$/)]) ],
    'country': [ '', Validators.required ],
    'facebookUrl': [ ''],
    'instagramUrl': [ '' ],
    'twitterUrl': ['' ],
    'youtubeUrl': ['']

  })

 }
  // platView: any;
  platId: any;
  
 get formC() {
  return this.signupForm.controls
}
platView : any
getPlatformData(){
    this.service.postApi(`static/contactList`,{},1).subscribe(response =>{
      if(response.response_code == 200){
        this.platView = response.result.docs[0]
        this.signupForm.patchValue({
          address : response.result.docs[0].address,
          phoneNo : response.result.docs[0].landLine,
          otheremail : response.result.docs[0].otherEmail,
          email :response.result.docs[0].email,
          mobCode :response.result.docs[0].countryCode,
          mobile : response.result.docs[0].mobileNumber,
          // country :response.result.docs[0].address,
          facebookUrl : response.result.docs[0].facebookUrl,
          instagramUrl : response.result.docs[0].instagramUrl,
          twitterUrl :response.result.docs[0].twitterUrl,
          youtubeUrl :response.result.docs[0].youTubeUrl,
        })
        console.log(this.platView)
        
      }

    },error =>{

    })

  }
 getContact() {
   // 
    this.spinner.show()
    this.service.getApi(`static/contactList`, 1).subscribe(response => {
      if (response['response_code'] == 200) {
        let data = response['data']
        this.spinner.hide();
        this.signupForm.patchValue({
              'title':data.title || '',
               'mobileNumber':data.mobileNumber || '',
               'twitterUrl':data.twitterUrl || '',
               'youTubeUrl':data.youtubeUrl || '',
               'instagramUrl':data.instagramUrl || '',
               'facebookUrl':data.facebookUrl || '',
               'lastName':data.lastName || '',
               'firstName':data.firstName || '',
               'address':data.address || '',
               'email':data.email || '',
        })
      } else {
        this.spinner.hide();

        this.service.toastErr(response['response_message'])
      }
    }, error => {
      this.spinner.hide();
      this.service.toastErr('Something Went Wrong')
    })
  }

 saveDetails(data) {
   let input ={
    //  'title':data.mobCode.value,
    //  'mobileNumber':data.mobile.value,
    //  'twitterUrl':data.twitterUrl.value,
    //  'youTubeUrl':data.youtubeUrl.value,
    //  'instagramUrl':data.instagramUrl.value,
    //  'facebookUrl':data.facebookUrl.value,
    //  'lastName':data.phoneNo.value,
    //  'firstName':data.otheremail.value,
    //  'address':data.address.value,
    //  'email':data.email.value,
    "contactId" : this.platView._id,
    "title": data.mobCode.value,
    "mobileNumber": data.mobile.value,
    "email": data.email.value,
    "address": data.address.value,
    "firstName": data.otheremail.value,
    "lastName": data.phoneNo.value,
    "facebookUrl": data.facebookUrl.value,
    "instagramUrl": data.instagramUrl.value,
    "twitterUrl": data.twitterUrl.value,
    "youTubeUrl": data.youtubeUrl.value,
   }
   console.log(input);
   
    this.service.postApi(`static/editContact`,input, 1).subscribe(response => {
      if (response['response_code'] == 200) {
        this.router.navigate(['/admin/content-management'])
        this.service.showSuccess(response['response_message'])


      }else{
      this.service.toastErr(response['response_message'])

      }
    }, error => {
      this.spinner.hide();
      console.log("error", error)
    })
  }
}
