import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '../../../../node_modules/@angular/forms';
import { MainService } from '../../providers/mainService';
import { Router } from '../../../../node_modules/@angular/router';
import { validateConfig } from '../../../../node_modules/@angular/router/src/config';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-edit-business-detail',
  templateUrl: './edit-business-detail.component.html',
  styleUrls: ['./edit-business-detail.component.css']
})
export class EditBusinessDetailComponent implements OnInit {

  editForm: FormGroup
  business: any;
  Id: any = '';
  DOB: string = ''

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 }
  };
  imageUrl: any;
  constructor(private service: MainService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder) {
    this.editForm = formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z ]{2,40}$/), Validators.minLength(2)])],
      bname: ['', Validators.compose([Validators.required, Validators.pattern(/^[^\s][a-zA-Z\s]*$/)])],
      gender: [''],
      DOB: ['']
    })
    this.onDateChanged()
  }

  ngOnInit() {
    this.spinner.show()
    this.Id = localStorage.getItem('_id of business')
    this.viewParticularbusiness()
  }

  //==========================================================VIEW PARTICULAR business API ====================================================================
  viewParticularbusiness() {
    this.spinner.show()
    this.service.getApi(`admin/viewDetail/${this.Id}`, 1).subscribe(response => {
      if (response['response_code'] == 200) {
        this.spinner.hide();
        this.business = response[`result`]
        this.editForm.patchValue({
          name: this.business.name || '',
          bname: this.business.businessName || '',
          gender: this.business.gender || '',
          DOB: this.business.dateOfBirth || '',
        })
        this.business = response[`result`]
        if (response['result'].profilePic) {
          this.imageUrl = this.business.profilePic
        } else {
          this.imageUrl = 'assets/img/default_profile_image.png'
        }
        if (response['result'].dateOfBirth) {
          this.DOB = this.business.dateOfBirth.formatted
        } else { this.DOB = "" }
      } else {
        this.service.toastErr(response['response_message'])
      }
    }, error => {
      this.spinner.hide();
      this.service.toastErr('Something Went Wrong')
    })
  }
  save() {
    let postData = {
      "_id": this.Id,
      "userType": "BUSINESS",
      "name": this.editForm.get('name').value,
      "businessName": this.editForm.get('bname').value,
      "gender": this.editForm.get('gender').value,
      "dateOfBirth": this.editForm.get('DOB').value
    }
    this.spinner.show()
    this.service.postApi('admin/edit/', postData, 1).subscribe(response => {
      this.spinner.hide();
      if (response['response_code'] == 200) {
        console.log(`view particular user ${response}`)
        this.route.navigate(['/business-management'])
      } else {
        this.service.toastErr(response['response_message'])
      }
    }, error => {
      this.spinner.hide();
      this.service.toastErr('Something Went Wrong')
    })
  }
  cancel() {
    this.route.navigate(['/business-management'])
  }
  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.myDatePickerOptions = copy1;
  }
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }
}
