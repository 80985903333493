import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../../providers/mainService';

@Component({
  selector: 'app-subscription-view',
  templateUrl: './subscription-view.component.html',
  styleUrls: ['./subscription-view.component.css']
})
export class SubscriptionViewComponent implements OnInit {
  organizerList: any
  SelectPlan: any
  subscriptionForm: any
  subscriptionData: any;
  _id: any;
  type = '';
  subscriptionType: any;
  flag = 0
  apiData: any
  chatData: any
  dropdownListCompetitionDatabase = [];
  dropdownListMembershipDatabase = []
  selectedItemsCompetition = [];
  selectedItemsMembershipDataBase = []
  selectedItemsMembership = []
  dropdownListMembership = []
  dropdownSettings = {};
  id: any
  subscriptionId: any;
  constructor(
    private route: ActivatedRoute,
    private service: MainService,
    private router: Router
  ) {
    this.route.params.subscribe(param => {
      this._id = param
      if (this._id.type == "Venue") {
        this.id = "venue"
      } else if (this._id.type == "Membership") {
        this.id = "membership"
      } else if (this._id.type == "Competition") {
        this.id = "competition"
      }
    })
  }

  ngOnInit() {
    // console.log(" this._id", this._id)
    this.SelectPlan = ''
    this.form();       //Form
    this.getList(); //    GetList   
    this.dropdownListCompetitionDatabase = [
      'Team',
      'Matches',
      'Player',
      'Clubs',
      'Refree',
      'Newsletter',
      'Activity Log',
      'Venue',
      'Sponsor',
    ];
    // this.selectedItemsCompetition = [    'Matches'    ];
    //...........................................membership..............................//

    if (this._id.type == 'Venue') {
      this.dropdownListMembershipDatabase = [ 'Player' ,  'Newsletter' ,  'Activity Log' ,  'Sponsor' ];
      // this.selectedItemsMembershipDataBase = [ 'Activity Log' ,  'Sponsor' ];

      this.dropdownListMembership = [ 'Dashboard' ,  'Booking' ,  'Stores' ,  'Configuration' ,  'Section' ,  'Venue' ];
      // this.selectedItemsMembership = [ 'Dashboard' ,  'Booking' ,  'Stores' ,  'Configuration' ,  'Section' ,  'Venue' ];

    }else if (this._id.type == 'Membership') {

      this.dropdownListMembershipDatabase = [ 'Player' ,  'Clubs' ,  'Venue' ,  'Sponsor' ,  'Newsletter' ,  'Activity Log' ];
      this.selectedItemsMembershipDataBase = [ 'Newsletter' ];

      this.dropdownListMembership = [ 'Registration' ,  'Member Card' ,  'Attendance' ,  'Leaderboard' ,  'Evaluation' ,  'Approval' ,  'Settings' ,  'Report' ,  'Section' ];
      // this.selectedItemsMembership = [ 'Evaluation' ,  'Approval' ,  'Settings' ,  'Report' ];
    }
    console.log("this._id.type", this._id.type)
    console.log("this.dropdownListMembershipDatabase", this.dropdownListMembershipDatabase)
    console.log("this.selectedItemsMembershipDataBase", this.selectedItemsMembershipDataBase)

    console.log("this.dropdownListMembership", this.dropdownListMembership)
    console.log("this.selectedItemsMembership", this.selectedItemsMembership)
    this.dropdownSettings = {
      singleSelection: false,
      idField: '',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  //................................Form Validation ,......................................................
  form() {
    this.subscriptionForm = new FormGroup({
      competitionDataBase: new FormControl('', [Validators.required]),
     
      membershipDataBase: new FormControl('', [Validators.required]),
      membershipMembership: new FormControl('', [Validators.required]),



      webAndHosting: new FormControl('', [Validators.required,Validators.pattern('[0-9]*')]),
      eventAndMemberShipManagement: new FormControl('', [Validators.required,Validators.pattern('[0-9]*')]),

      oneEvent: new FormControl('', [Validators.required,Validators.pattern('[0-9]*')]),
      monthly: new FormControl('', [Validators.required,Validators.pattern('[0-9]*')]),
      yearly: new FormControl('', [Validators.required,Validators.pattern('[0-9]*')]),

      Media: new FormControl(false, [Validators.required]),
      Product: new FormControl(false, [Validators.required]),
      userManagement: new FormControl(false, [Validators.required]),
    })
  }
  //..........................getList Function..............................................................
  getList() {
    this.service.getApi((`admin/detailSubscription`), 1).subscribe(success => {
      console.log("Success", success)
      if (success.responseCode == 200) {
        this.subscriptionId=success.result[0]._id
        this.subscriptionData = success.result
        this.subscriptionType = success.result[0].subscriptionAccess


        // console.log("this.subscriptionType.Media",success.result[0].subscriptionAccess.Media)
        this.subscriptionForm.controls['Media'].setValue(this.subscriptionType.Media);
        this.subscriptionForm.controls['Product'].setValue(this.subscriptionType.Product);
        this.subscriptionForm.controls['userManagement'].setValue(this.subscriptionType.userManagement);

        this.subscriptionForm.controls['webAndHosting'].setValue(this.subscriptionType.addOn.webAndHosting);
        this.subscriptionForm.controls['eventAndMemberShipManagement'].setValue(this.subscriptionType.addOn.eventAndMemberShipManagement);

        this.setValueFunc()    //   set Value for oneEvent,monthly, yearly 
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
      this.service.toastErr("Something went wrong")
    })
  }





  setValueFunc() {
    if (this.id == "competition") {
      this.subscriptionForm.controls['oneEvent'].setValue(this.subscriptionType.competition.oneEvent);
      this.subscriptionForm.controls['monthly'].setValue(this.subscriptionType.competition.monthly);
      this.subscriptionForm.controls['yearly'].setValue(this.subscriptionType.competition.yearly);
      this.selectedItemsCompetition=this.subscriptionType.competition.database
    } else if (this.id == "membership") {
      this.subscriptionForm.controls['oneEvent'].setValue(this.subscriptionType.membership.oneEvent);
      this.subscriptionForm.controls['monthly'].setValue(this.subscriptionType.membership.monthly);
      this.subscriptionForm.controls['yearly'].setValue(this.subscriptionType.membership.yearly);
     
      this.selectedItemsMembershipDataBase=this.subscriptionType.membership.database
      this.selectedItemsMembership=this.subscriptionType.membership.membership
      
    } else if (this.id == "venue") {
      this.subscriptionForm.controls['oneEvent'].setValue(this.subscriptionType.venue.oneEvent);
      this.subscriptionForm.controls['monthly'].setValue(this.subscriptionType.venue.monthly);
      this.subscriptionForm.controls['yearly'].setValue(this.subscriptionType.venue.yearly);
      this.selectedItemsMembershipDataBase=this.subscriptionType.venue.database
      this.selectedItemsMembership=this.subscriptionType.venue.membership

    } 
  }
  //..................................................................................
  submitFunc(data) {
    console.log(data)
  
  if (data.oneEvent=='' || data.monthly=='' || 
  data.yearly=='' || data.webAndHosting=='' ||
  data.eventAndMemberShipManagement=='') {
      this.service.toastErr("Fill Correct Form")
      return
    }
    console.log(data)
    var apiData={}
    if (this._id.type == "Membership" ) {
      apiData={
        subscriptionId:  this.subscriptionId,
        subscriptionAccess:{
          competition:this.subscriptionType.competition,
          membership:{
          database:data.membershipDataBase  ,
          membership:data.membershipMembership,
          oneEvent:data.oneEvent,
          monthly:data.monthly,
          yearly:data.yearly 
          },
          venue:this.subscriptionType.venue,
          Media:data.Media,
          Product:data.Product,
          userManagement : data.userManagement,
          addOn:{
          webAndHosting:data.webAndHosting,
          eventAndMemberShipManagement:data.eventAndMemberShipManagement          }
          }
      } 
    }else if (this._id.type == "Venue") {
      console.log("Venue Api req")
      apiData={
        subscriptionId:  this.subscriptionId,
        subscriptionAccess:{
          competition:this.subscriptionType.competition,
          membership:this.subscriptionType.membership,
          venue:{
            database:data.membershipDataBase  ,
            venue:data.membershipMembership,
            oneEvent:data.oneEvent,
            monthly:data.monthly,
            yearly:data.yearly 
            }
          ,
          Media:data.Media,
          Product:data.Product,
          userManagement : data.userManagement,
          addOn:{
          webAndHosting:data.webAndHosting,
          eventAndMemberShipManagement:data.eventAndMemberShipManagement          }
          }
          }
      }
     else if (this._id.type == "Competition") {
      console.log("Competition Api req")
      apiData={
        subscriptionId:  this.subscriptionId,
        subscriptionAccess:{
          competition:{
          database: data. competitionDataBase,
          competition: [],
          oneEvent:data.oneEvent,
          monthly:data.monthly,
          yearly:data.yearly 
          },
          membership:this.subscriptionType.membership,
          venue:this.subscriptionType.venue,

          Media:data.Media,
          Product:data.Product,
          userManagement : data.userManagement,
          addOn:{
          webAndHosting:data.webAndHosting,
          eventAndMemberShipManagement:data.eventAndMemberShipManagement          }
          }
          }

      }

      console.log("ApiRequest", apiData)
  
    this.service.postApi(`admin/editSubscription`, apiData, 1).subscribe(success => {
      console.log("success=====>>getList", success)
      if (success.responseCode == 200) {
        this.router.navigate(['/subscriptionList'])
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
      this.service.toastErr("Something went wrong")
    })
  }
  //.............................................. End Edit Subscription Data..............................


}
