import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '../../../../node_modules/@angular/forms';
import { MainService } from '../../providers/mainService';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { setRootDomAdapter } from '../../../../node_modules/@angular/platform-browser/src/dom/dom_adapter';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any
import { Angular5Csv } from 'angular5-csv/Angular5-csv';

@Component({
  selector: 'app-transaction-management',
  templateUrl: './transaction-management.component.html',
  styleUrls: ['./transaction-management.component.css']
})
export class TransactionManagementComponent implements OnInit {

  transantionForm: FormGroup;
  paginationData: any = {};
  srNo: number;
  modified = []
  Transaction: any = [];
  pageSize: any = {};
  pageNo: any = 1;
  status: any
  beDisable = true
  todate: any;


  private fromDate: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };

  public toDate: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };
  total: any;
  limit: any;

  constructor
    (
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    public service: MainService
    ) {
    this.transantionForm = fb.group({
      'search': [''],
      'from': [null],
      'to': [null],
      'sort': [''],
    })
  }


  ngOnInit() {
    this.getAllTransaction()
  }

  //**************************** GET ALL transaction *************************************** */
  changePage(page) {
    this.pageNo = page
    this.getAllTransaction()
  }

  filter(num: number) {
    this.pageNo = 1
    setTimeout(() => {
      this.getAllTransaction()
    }, 300)

  }
  getAllTransaction() {
    if (!(this.transantionForm.value.from && this.transantionForm.value.to)) {
      this.beDisable = true
      this.transantionForm.patchValue({
        'to': null,
        'from': null
      })
    }
    let postData = {
      search: this.transantionForm.controls['search'].value,
      bookingStatus: this.transantionForm.controls['sort'].value,
      fromDate: this.transantionForm.value.from ? this.transantionForm.value.from.formatted : '',
      toDate: this.transantionForm.value.to ? this.transantionForm.value.to.formatted : '',
      pageNumber: this.pageNo
    }

    // this.service.postApi(`event/transactionManagementFilter`,postData,1).subscribe(response => {
      this.spinner.show()
      //  this.service.postApi(`admin/transactionManagement`, postData, 1).subscribe(response => {
        this.service.postApi(`admin/transactionManagement`, postData, 1).subscribe(response => {

              this.spinner.hide();
      console.log("=====>>>", response)

      if (response['responseCode'] == 200) {
        this.Transaction = response.result.docs
        this.total = response.result.total
        this.srNo = (this.pageNo - 1) * response.result.limit
        this.limit = response.result.limit
      } else {

      }
      // if (response['response_code'] == 200) {
      //   this.modified = response[`result`]
      //   this.Transaction=this.modified
      //   for(let i=0; i<this.modified.length; i++){
      //     if(this.modified[i].bookingStatus == 'CANCELLED'){
      //       this.paginationData = response[`paginationData`]
      //       this.srNo = (this.pageNo - 1) * this.paginationData.limit
      //                }
      //     else if(this.modified[i].bookingStatus == 'CONFIRMED'){
      //       this.paginationData = response[`paginationData`]
      //       this.srNo = (this.pageNo - 1) * this.paginationData.limit
      //     }

      //   }
      //   this.paginationData = response[`paginationData`]
      //   this.srNo = (this.pageNo - 1) * this.paginationData.limit
      // } else {
      //   this.Transaction = [];
      //   this.paginationData = {total: 0, limit: 10}
      // }
    }, error => {
          this.spinner.hide();
      this.service.toastErr('Something Went Wrong')
    })
  }
  onFromDate(event: IMyDateModel) {
    if (event.formatted) {
      this.beDisable = false
      let d: Date = new Date(event.jsdate.getTime());
      d.setDate(d.getDate() - 1);
      let copy: IMyDpOptions = this.getCopyOfToDateOpt();
      copy.disableUntil = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
      this.toDate = copy;
    } else {
      this.beDisable = true
      this.transantionForm.patchValue({
        'to': null,
        'from': null
      })
      this.getAllTransaction()
    }
  }
  getCopyOfToDateOpt(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.toDate));
  }
  onToDate(event) {
    if (event.formatted) {
      setTimeout(() => {
        if (this.transantionForm.controls['to'].value.formatted && this.transantionForm.controls['from'].value.formatted) {
          this.filter(3)
        }
        else {
          this.transantionForm.patchValue({
            'to': null
          })
        }
      }, 300)
    }
  }
   print(){
    let printContents = document.getElementById('tablerecords').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload()
  }

  exportToCSV(){
    var data = [
      {
        TrnasactionId: '  Trnasaction Id',
        TransactionDoneBy: 'Transaction Done By',
        Receiver: 'Receiver',
        TransactionType: 'Transaction Type',
        Amount: 'Amount' ,
        Date:'Date',
        // subscription:'Subscription'
      }
    ];
    for(let i=0; i < this.modified.length; i++) {
      data.push({
        TrnasactionId: this.modified[i].TrnasactionId,
        TransactionDoneBy: this.modified[i].transactionDoneBy,
        Receiver: this.modified[i].receiver || '- - -',
        TransactionType: this.modified[i].transactionType,
        Amount: this.modified[i].amount,
        Date:this.modified[i].date,
        // subscription: this.modified[i].subscription,

      })
      if(i+1 == this.modified.length) 
        new Angular5Csv(data, 'management');
    }
    console.log("Data----->  ",data);
  }
}
