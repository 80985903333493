import { Component, OnInit } from '@angular/core';
import { Router } from '../../../../node_modules/@angular/router';
import { MainService } from '../../providers/mainService';
import { FormBuilder, FormGroup } from '../../../../node_modules/@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css']
})
export class BusinessComponent implements OnInit {

  pageNo: any = 1;
  collection = [];
  business: any = [];
  id: any = '';
  BusinessForm: FormGroup;
  paginationData: any = {};
  srNo: number;
  modified = []
  status: any

  constructor(private router: Router,
    public service: MainService,
    private spinner: NgxSpinnerService,
    public fb: FormBuilder) {
    this.BusinessForm = this.fb.group({
      'search': [''],
      'sort': [''],
      'pageNumber': ['']
    })
    for (let i = 1; i <= 15; i++) {
      this.collection.push(`item ${i}`);
    }
  }

  ngOnInit() {
    this.search()

  }

  changePage(page) {
    this.pageNo = page
    this.search()
  }
  filter() {
    this.pageNo = 1;
    this.search()
  }

  view(l) {
    localStorage.setItem('_id of business', this.business[l]._id);
    this.router.navigate(['./businessDetails']);
  }

  edit(l) {
    localStorage.setItem('_id of business', this.business[l]._id);
    this.router.navigate(['./editBusinessDetail']);
  }

  userdel(l) {
    localStorage.setItem('_id of business', this.business[l]._id);
    $('#delete').modal('show');
  }

  delete(l) {
    this.spinner.show()
    let delData = {
      '_id': localStorage.getItem('_id of business'),
      "userType": "BUSINESS"
    }
    this.service.postApi(`admin/deleteUser`, delData, 1).subscribe(response => {
      if (response['response_code'] == 200) {
        localStorage.removeItem('_id of business')
        this.search()
      } else {
        this.service.toastErr(response['response_message'])
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.service.toastErr('Something Went Wrong')
    })
    $('#delete').modal('hide');
  }
  userStatus(item) {
    this.spinner.show()
 
    this.status = item.status;
    if (this.status == "ACTIVE" || "BLOCK") {
      let delData = { '_id': item._id }
      this.service.postApi(`admin/blockUser`, delData, 1).subscribe(response => {
        if (response['response_code'] == 200) {
        } else {
          this.modified = [];
        }
     
  this.spinner.hide();
      }, error => {
    
  this.spinner.hide();
        this.service.toastErr('Something Went Wrong')
      })
    }
  }

  search() {
    this.spinner.show()
     let postData = {
      search: this.BusinessForm.controls['search'].value,
      status: this.BusinessForm.controls['sort'].value,
      userType: "BUSINESS",
      pageNumber: this.pageNo
    }
    for (let val in postData) {
      if (postData[val] == '') {
        delete postData[val]
      }
    }
    this.service.postApi(`user/searchCustomerFilter`, postData, 1).subscribe(response => {
      if (response['response_code'] == 200) {
        this.business = response['result']
        this.modified = this.business
        for (let i = 0; i < this.business.length; i++) {
          if (this.business[i].status == 'ACTIVE') {
            this.modified[i].status = true
          } else if (this.business[i].status == 'BLOCK') {
            this.modified[i].status = false
          }
        }
        this.paginationData = response['paginationData']
        this.srNo = (this.pageNo - 1) * this.paginationData.limit
      } else {
        this.paginationData = { total: 0, limit: 10 }
        this.modified = [];
      }
       this.spinner.hide();
    }, error => {
    })
  }
}

