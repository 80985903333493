import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from '../../../providers/mainService';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-competition-management-edit',
  templateUrl: './competition-management-edit.component.html',
  styleUrls: ['./competition-management-edit.component.css']
})
export class CompetitionManagementEditComponent implements OnInit {
  editForm: FormGroup
  userData: any;
  constructor(
    private service: MainService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.formControl()
    // this.viewCompetition()
  
   }

  //............FormControl Function............................
  formControl() {
    this.editForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      countryCode: new FormControl('', [Validators.required]),
      mobileNumber: new FormControl('', [Validators.required]),
    })
  }
  //............ End FormControl Function........................................

  //............ View Particular competition  Function............................

  viewCompetition() {
    let apiData = {

    }
    this.service.postApi('', apiData, 1).subscribe(success => {
      console.log("success=======>>>",success)
      if (success.responseCode == 200) {
        // this.userData=success.result
        this.editForm.controls['firstName'].setValue(this.userData.firstName)
        this.editForm.controls['lastName'].setValue(this.userData.firstName)
        this.editForm.controls['gender'].setValue(this.userData.firstName)
        this.editForm.controls['countryCode'].setValue(this.userData.firstName)
        this.editForm.controls['mobileNumber'].setValue(this.userData.firstName)
      } else {

      }
    }, error => {
      this.service.toastErr("Something went wrong..!")
    })
  }
  //............ View Particular competition  Function............................

  save(data){
    if(this.editForm.invalid)
    {
      this.service.toastErr("Please fill form correct...!")
      return
    }
    console.log("data=========>>>",data)
    
  }
}