import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../providers/mainService';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard-management',
  templateUrl: './dashboard-management.component.html',
  styleUrls: ['./dashboard-management.component.css']
})
export class DashboardManagementComponent implements OnInit {
  count: any;

  constructor(
    private service: MainService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.getCount()
  }
  //...................... Get Count Function......................................
  getCount() {
    this.spinner.show()
    
    this.service.getApi('admin/getAll', 1).subscribe(response => {

      if (response.responseCode == 200) {
        this.count = response.result
      } 
    
      this.spinner.hide()
    }, error => {
     
      this.spinner.hide()
      this.service.toastErr("Something went wrong")
    })
  }
  //...................... End  Get Count Function......................................

}
