import { Component, OnInit } from '@angular/core';
import { MainService } from '../../providers/mainService';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $

@Component({
  selector: 'app-competition-management',
  templateUrl: './competition-management.component.html',
  styleUrls: ['./competition-management.component.css']
})
export class CompetitionManagementComponent implements OnInit {
  competitionForm: FormGroup
  competitionData: any;
  total: any;
  limit: any;
  page: any;
  pageNo: any = 1;
  competitionLength: any;
  customer: any = [];
  srNo: number;
  searchData: any = { search: '', status: '' };
  id: any;
  stat: any;
  _id: any;
  realStatus: string = "BLOCK";
  status: any;


  constructor(
    private service: MainService,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute
  ) { 
    this.router.params.subscribe(params => {
      this.id = params.id
      console.log(this.id)
    });
  }

  ngOnInit() {
    this.formValidation();
    this.getList();
    this.spinner.show()
  }

  formValidation() {
    this.competitionForm = new FormGroup({
      search: new FormControl('', [Validators.required]),
      sort: new FormControl('', [Validators.required])
    })
  }

  resetFunction() {
    this.competitionForm.controls['search'].setValue('')
    this.searchData.search = ''
    this.getList()
  }

  filter(data) {
    this.searchData = data
    this.pageNo = 1
    this.getList()
  }
  filerStatus(data) {
    this.searchData = data
    this.pageNo = 1
    this.getList()
  }

  getList() {
    this.spinner.show()
    let apiData = {
      "page": this.pageNo,
      "limit": 10,
      "search": this.searchData.search,
    }
    console.log(apiData)
    this.service.postApi('admin/getCompetition', apiData, 1).subscribe(success => {
      console.log(success)
      if (success.responseCode == 200) {
        this.competitionData = success.result.docs
        this.competitionLength = this.competitionData.length
        this.total = success.result.total
        this.limit = success.result.limit
        this.page = success.result.page
        this.srNo = (this.pageNo - 1) * this.limit;
      } else {
        this.service.toastErr(success.responseMessage)
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.service.toastErr("Somethng went wrong")
    })
  }

  deleteId:any;
deleteComptition(deleteId){
this.deleteId = deleteId
$('#delete').modal('show');
}

// competition delete
  // competitiondel() {
  //   localStorage.setItem('_id of customer', l);
  //   $('#delete').modal('show');
  // }
  delete() {
    this.spinner.show()
  const reqUrl = `admin/deleteCompetition?_id=${this.deleteId}`
  let apiData = {
    "competitionId": this.deleteId,
    "userid" : localStorage.getItem('adminId')
       
  }
    // this.service.getApi(`admin/deleteCompetition?_id=${localStorage.getItem('_id of customer')}`, 1)
    this.service.postApi(reqUrl,apiData,1).subscribe(response => {
      if (response['responseCode'] == 200) {
        // localStorage.removeItem('adminId')
        this.service.showSuccess(response['responseMessage'])
        // localStorage.removeItem('_id of customer')
        this.getList()
        // this.search()
      }
      else {
        this.service.toastErr(response['responseMessage'])
      }
    
      this.spinner.hide()
    }, error => {
    
      this.spinner.hide()
      this.service.toastErr('Something Went Wrong');
    })
    $('#delete').modal('hide');

  }


  //    change status...

  changeStatus(_id, status) {
    console.log("_id", _id)
    this.stat = status
    this._id = _id
    $('#block').modal('show');

  }

  blockUnblock() {
    this.spinner.show()
  
    let delData = { '_id': this._id, status: this.stat }
    const url = `admin/blockUnblockCompetition?_id=${this._id}`
    console.log("delData", delData)
    $('#block').modal('hide');
    this.service.postApi(url, delData, 1).subscribe(response => {
      console.log("change status", response)
      if (response['responseCode'] == 200) {
        this.service.showSuccess(response[`responseMessage`])

        this.getList();
      }
      else {
        this.service.toastErr(response['responseMessage'])
      }
     
      this.spinner.hide()
    }, error => {
   
      this.spinner.hide()
      this.service.toastErr('Something Went Wrong');
    })

  }
  userStatus(item) {
    // console.log("item to block and activate",item)

    this.status = item.status;


    if (!this.status) {

      this.realStatus = "ACTIVE";
      // console.log("anurag",this.realStatus)
    }

    let delData = { '_id': item._id, status: this.realStatus }

    console.log("by toggle", delData)
    // this.service.postApi(`users/updateUser`, delData, 1).subscribe(response => {
    //   if (response['responseCode'] == 200) {
    //     // this. search();
    //   }
    //   else {
    //     this.service.toastErr(response['responseMessage'])
    //   }
    // }, error => {
    //   this.service.toastErr('Something Went Wrong');
    // })

  }

  changePage(page) {
    this.pageNo = page
    this.getList()
  }
}
