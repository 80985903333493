import { Component, OnInit } from '@angular/core';
import { MainService } from '../../providers/mainService';
import { Router } from '../../../../node_modules/@angular/router';
import { FormGroup, FormBuilder, Validators } from '../../../../node_modules/@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-edit-user-detail',
  templateUrl: './edit-user-detail.component.html',
  styleUrls: ['./edit-user-detail.component.css']
})
export class EditUserDetailComponent implements OnInit {
  editForm: FormGroup
  customer: any;
  Id: any = '';
  image: string | ArrayBuffer;
  codeArray: any = [];
  CompetitionMembershipFlag: boolean
  CompetitionRoleFlag: boolean = false;
  CompetitionVenueFlag: boolean;
  loginType = [];
  organizerType = [];
  showEye:any='password'
  showEyeConfirm:any="password"
  defaultAccess = { competition: '', membership: '', venue: '' };
  userSubscriptionAccess: any;
  constructor(
    private service: MainService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder
  ) {
    this.editForm = formBuilder.group({
      firstName: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z ]{2,40}$/), Validators.minLength(2)])],
      lastName: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z ]{2,40}$/), Validators.minLength(2)])],
      gender: ['', Validators.compose([])],
      CompetitionRole: ['', Validators.compose([])],
      CompetitionMembership: ['', Validators.compose([])],
      CompetitionVenue: ['', Validators.compose([])],
      countryCode: ['', Validators.compose([Validators.required])],
      mobileNumber: ['', Validators.compose([Validators.required, Validators.pattern(/^[1-9][0-9]{6,13}$/)])],
      'email': ['', Validators.compose([Validators.required, Validators.pattern(/^[A-Z0-9_-]+([\.][A-Z0-9_]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,3})+$/i), Validators.maxLength(50)])],
       'password':['',Validators.compose([Validators.required,Validators.pattern(/^(?=^.{8,16}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-])(?!.*\s).*$/),Validators.minLength(8)])],
       'confirmpassword':['',Validators.compose([Validators.required])]

      })
  }

  async  ngOnInit() {
    this.spinner.show()
    await this.getAccess();
    this.Id = localStorage.getItem('_id of customer')
    await this.viewParticularCustomer();
    await this.getCountryCode();
  }
  //==========================================================VIEW PARTICULAR CUSTOMER API ====================================================================
  getAccess() {
    this.spinner.show()
    this.service.getApi(`admin/getSubscriptionAcessData`, 1).subscribe(response => {
      if (response['responseCode'] == 200) {
        this.spinner.hide();
        this.defaultAccess = response.result[0].subscriptionAccess
        console.log("default access===>>>>>", this.defaultAccess)
      }
    }, error => {
      this.spinner.hide();
      console.log("error", error)
    })
  }

  viewParticularCustomer() {
    this.spinner.show()
    this.service.getApi(`users/getDetail?_id=${this.Id}`, 1).subscribe(response => {
      this.spinner.hide();
      if (response['responseCode'] == 200) {
        this.customer = response[`result`]
        this.loginType = this.customer.organizerType;
        this.userSubscriptionAccess = this.customer.subscriptionAccess;
        this.editForm.patchValue({
          firstName: this.customer.firstName || '',
          gender: this.customer.gender == "male" || this.customer.gender == "Male" ? "Male" : "Female",
          lastName: this.customer.lastName || '',
          email: this.customer.email || '',
          countryCode: this.customer.countryCode || '',
          mobileNumber: this.customer.mobileNumber || '',
          image: this.customer.image || "assets/img/default_profile_image.png"
        })
        if (response['result'].image) {
          this.image = this.customer.image
        }
        else {
          this.image = 'assets/img/default_profile_image.png'
        }
      }
      else {
        this.service.toastErr(response['responseMessage'])
      }
    }, error => {
      this.spinner.hide();
      this.service.toastErr('Something Went Wrong')
    })
  }
  //...................change update Api ...................
  check(ev, val) {
    if (!this.organizerType.includes(val))
      this.organizerType.push(val);
    if (ev.target.checked == false) {
      this.organizerType.indexOf(val)
      this.organizerType.splice((this.organizerType.indexOf(val)), 1)
    }
  }


  //..............................Edit User Api................... 
  save() {
    let z = []
    z = this.organizerType.concat(this.loginType)
    function onlyUnique(value, index, self) { return self.indexOf(value) === index; }
    var unique = z.filter(onlyUnique);
    if (this.organizerType.includes("COMPETITION") && (!this.loginType.includes('COMPETITION'))) {
      this.userSubscriptionAccess['competition'] = this.defaultAccess.competition
    } else if (this.organizerType.includes("COMPETITION") && (this.loginType.includes('COMPETITION'))) {
      this.userSubscriptionAccess['competition'] = this.userSubscriptionAccess.competition
    }
    if (this.organizerType.includes("MEMBERSHIP") && (!this.loginType.includes('MEMBERSHIP'))) {
      this.userSubscriptionAccess['membership'] = this.defaultAccess.membership;
    } else if (this.organizerType.includes("MEMBERSHIP") && (this.loginType.includes('MEMBERSHIP'))) {
      this.userSubscriptionAccess['membership'] = this.userSubscriptionAccess.membership
    }
    if (this.organizerType.includes("VENUE") && (!this.loginType.includes('VENUE'))) {
      this.userSubscriptionAccess['venue'] = this.defaultAccess.venue

    } else if (this.organizerType.includes("VENUE") && (this.loginType.includes('VENUE'))) {
      this.userSubscriptionAccess['venue'] = this.userSubscriptionAccess.venue
    }

    let postData = {
      "_id": this.Id,
      "firstName": this.editForm.get('firstName').value,
      "lastName": this.editForm.get('lastName').value,
      "gender": this.editForm.get('gender').value,
      "email": this.editForm.get('email').value,
      "countryCode": this.editForm.value.countryCode,
      "mobileNumber": this.editForm.value.mobileNumber,
      "image": this.image,
      "organizerType": unique,
      'password':this.editForm.value.password,
      subscriptionAccess: this.userSubscriptionAccess
    }
    this.spinner.show()
    this.service.postApi('users/updateUserAdmin', postData, 1).subscribe(response => {
      this.spinner.hide();
      if (response['responseCode'] == 200) {
        this.service.showSuccess(response[`responseMessage`])
        window.history.back()
      } else {
        this.service.toastErr(response['responseMessage'])
      }
    }, error => {
      this.spinner.hide();
      this.service.toastErr('Something Went Wrong')
    })
  }
  cancel() {
    window.history.back()
  }
  changeListener($event): void {
    this.readThis($event.target);
  }
  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.image = myReader.result;
    }
    myReader.readAsDataURL(file);
  }

  getCountryCode() {
    this.spinner.show()
    this.service.getApi('users/code', 1).subscribe(response => {
      this.spinner.hide();
      if (response['responseCode'] == 200) {
        this.codeArray = response["result"];
      }
      else {
        this.service.toastErr(response['responseMessage'])
      }
    }, error => {
      this.spinner.hide();
      this.service.toastErr('Something Went Wrong')
    })
  }

  // password eye hide/show
  showHidePassword() {
    this.showEye = !this.showEye
  }
  showEyeConfirmPassword(){
    this.showEyeConfirm = !this.showEyeConfirm
  }
}
