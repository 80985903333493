
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { query } from '@angular/animations';
declare var $: any;
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  isActive:any;
  service: any;
  @Input() activeMenu;
  constructor(public router: Router) { 
  }

  ngOnInit() {
    // this.isActive = event;
    // let url = window.location.href.split('/')
    // let page = url[url.length-1]
    // this.isActive= page;
  }

  // tabManag(event,type) {
  //   if(type){
  //     this.isActive = event;
  //     let url = window.location.href.split('/')
  //     let page = url[url.length-1]
  //     this.isActive= page;
  //     this.router.navigate(['/'+event,{"type":type} ])
  //   }else{
  //     this.isActive = event;
  //     let url = window.location.href.split('/')
  //     let page = url[url.length-1]
  //     this.isActive= page;
  //     this.router.navigate(['/'+event])

  //   }
     
  // }
  logoutYes(){
    localStorage.removeItem('adminId')
    localStorage.removeItem('token')
    localStorage.removeItem('_id of customer')
    localStorage.removeItem('_id of business')
    localStorage.removeItem('control-edit')
    localStorage.removeItem('control')

    this.router.navigate(['/login'])
    $('#logout').modal('hide');
    
    
    }

    // changeToReset(){
    //   this.router.navigate(["/event-management"])
    // }
}



