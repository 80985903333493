import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MainService } from '../../providers/mainService';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-event-management',
  templateUrl: './event-management.component.html',
  styleUrls: ['./event-management.component.css']
})
export class EventManagementComponent implements OnInit {
  total: any
  eventForm: FormGroup;
  paginationData: any={};
  srNo: number;
  Event:any=[];
  pageSize:any= {};
  pageNo: any = 1;
  limit: any;
  resetForm: FormGroup;
 
  constructor(public route:ActivatedRoute,
    public service:MainService,
    private router: Router) {
    this.resetForm = new FormGroup ({
      'password': new FormControl('',[Validators.required,Validators.minLength(8)]),
      'newPassword': new FormControl('',[Validators.required,Validators.minLength(8)]),
      'confirmpassword':new FormControl('',[Validators.required])
    })
   }

  ngOnInit() {
    this.route.params.subscribe(params => { 
                  });
        }

  resetFunction(data){
    console.log(data);
 let resetBody={
      _id:localStorage.getItem("adminId"),
      password:data.password,
      newPassword:data.newPassword
    }
    this.service.postApi(`users/changePassword`,resetBody,1).subscribe(response=>{
      if(response['responseCode']==200) {
        this.service.showSuccess(response['responseMessage'])
        this.router.navigate(['/dashboard/organizer'])
        
      }
      else{
        this.service.toastErr(response['responseMessage'])
      }
      },error=>{
        this.service.toastErr(error)
      })
  }
   
  
  
}