import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from 'src/app/providers/mainService';

@Component({
  selector: 'app-faq-add',
  templateUrl: './faq-add.component.html',
  styleUrls: ['./faq-add.component.css']
})
export class FaqAddComponent implements OnInit {

  form: FormGroup;
  faqList: any;
  faqId: any;
  ques: any;
  ans : any;
  regex ="^[^\s]+[-a-zA-Z\s]+([-a-zA-Z]+)*$"
  constructor(private activatedroute: ActivatedRoute,private router : Router, public service:MainService, private spinner: NgxSpinnerService) {
    this.activatedroute.queryParams.subscribe((res) => {
      this.faqId = res.itemId;

    });
  }

  ngOnInit() {

    this.form = new FormGroup({
      "question": new FormControl('',[ Validators.required]),
      "answer": new FormControl('',[ Validators.required]),
    });
    // this.viewFaq();
    this.getPlatformData()
  }

  // add faq
  addFaq() {
    let url = 'static/addFaqs'
    const data = {
      "question" : this.form.get('question').value,
      "answer" : this.form.get('answer').value,
      "faqId" : this.faqId
    }
    this.spinner.show() 
    this.service.postApi(url,data,1).subscribe((res:any) => {
      if(res.response_code == 200){
        this.service.showSuccess(res.response_message)
         this.spinner.hide();
        this.router.navigate(['/help-view']);
      }
      else{
        this.service.toastErr(res.response_message)
         this.spinner.hide();
      }
    })
}

getPlatformData(){
  this.service.postApi(`static/staticContentList`, {},1).subscribe(response =>{
    if(response.response_code == 200){
         this.faqId = response.result.docs[1]._id
   console.log(this.faqId)
    }
  },error =>{
  })

}
}
