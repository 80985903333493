import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../providers/mainService';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-edit-video',
  templateUrl: './edit-video.component.html',
  styleUrls: ['./edit-video.component.css']
})
export class EditVideoComponent implements OnInit {
  email: any
  fd: any
  video: any = {}
  videoFlag: number = 0;
  linkFlag: number = 0;
  videoLink: string;
  staticVideo: any
  videoData: any;
  filePath: any;
  constructor(
    private service: MainService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.form()
    this.fd = new FormData();
    this.getVideo()
  }

  form() {
    this.staticVideo = new FormGroup({
      title: new FormControl(''),
      link: new FormControl(''),
      video: new FormControl(''),

    })
  }

  getVideo() {
    this.spinner.show()
      this.service.getApi('admin/detailVideo', 1).subscribe(success => {
      this.spinner.hide();
      if(success.responseCode==200){
        this.videoData=success.result
        this.videoLink=this.videoData.file
        console.log(success)
        console.log("this.videoData", this.videoLink)
        if(this.videoLink){
          if(this.videoData.title){
            this.staticVideo.controls['title'].setValue(this.videoData.title)
          }
          this.staticVideo.controls['link'].setValue(this.videoData.file)
        }
      } else{
        this.service.toastErr(success.responseMessage)

      }
    }, error => {
            this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }

  disableVideo() {
    this.videoFlag = 1
    console.log("disabled")
  }
  disableTitle() {
    console.log("disable title")
    this.staticVideo.controls['link'].setValue('')
    this.linkFlag = 1
  }
  refresh() {
    this.videoFlag = 0
    this.linkFlag = 0
  }
  videoUploadedFlag:any
  //admin/videoUpload?adminId=5bc0bf224199144a5cf7499f
  handleVideo(event) {

    this.videoUploadedFlag = true
    var self = this;
    if (event.target.files && event.target.files[0]) {
      this.videoUploadedFlag = false
      if (event.target.files[0].type == 'video/mp4' || event.target.files[0].type == 'video/avi' || event.target.files[0].type == 'video/mpg' || event.target.files[0].type == 'video/quicktime') {
        this.sendVideo(event.target.files[0])
        var reader = new FileReader()
      }
      else {
        this.service.toastErr('Please upload video file.')
        this.videoUploadedFlag = true
      }
    }
  }

  sendVideo(filedata) {
    let formdata = new FormData()
    let adminId = localStorage.getItem('adminId')
    formdata.append('file', filedata);
    // this.service.succtotal('Please wait video is uploading.')
    this.service.postApi('admin/videoUpload?adminId='+adminId, formdata,1).subscribe(resp => {
      if (resp['responseCode'] == 200) {
        this.videoLink =resp.Data.file
        // this.allUploadedVideoList.push({ 'video': resp['data'], 'selected': false })
        this.videoUploadedFlag = true
        // this.VideoUploadForRequest.push(resp['data'])
      } else {
        this.service.toastErr('something went wrong on server.')
        this.videoUploadedFlag = true
      }
    }, error => {
      this.service.toastErr('something went wrong on server.')
      this.videoUploadedFlag = true
    })
  }
//admin/videoUpload
  onUploadChange(evt: any) {
    this.disableTitle()
  
    const file = evt.target.files[0];
    console.log("Filename--->> ", file.name);
    this.forgot(file);
   
  }

  forgot(image) {
    if (image) {
      this.filePath=image
      this.fd.append('file', image);
    } else {
      this.fd.append('file', '');
    }
    console.log("ImageRequest--->>> ", this.fd)
  }

  upload(data) {
    this.spinner.show()
    console.log(data)

    this.fd.append('title',data.title)
    this.fd.append('URL',data.link)
    this.fd.append(`videoId`,this.videoData._id)
    this.service.postApiUpload(`admin/editVideo`,this.fd, 1).subscribe(response => {
      console.log(response);
            this.spinner.hide();
      if (response.responseCode == 200) {
      
        this.router.navigate(['/homePageContentManagement'])
      } else {
        this.service.toastErr(response.responseMessage);
      }
    }, error => {
      this.spinner.hide()
      console.log("Something Went Wrong");
    })
  }
}
