import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../../../providers/mainService';

@Component({
  selector: 'app-comprehensiv-feature',
  templateUrl: './comprehensiv-feature.component.html',
  styleUrls: ['./comprehensiv-feature.component.css']
})
export class ComprehensivFeatureComponent implements OnInit {
  featureData: any
  
  constructor(
    private service: MainService,
    private router: Router,
  ) { 
  }

  ngOnInit() {
    this.getList()
  }

  getList() {
    this.service.getApi(`admin/comprehensiveListing`, 1).subscribe(success => {
      console.log(success);
      
      if (success.responseCode == 200) {
        this.featureData = success.result
      } else {
        this.service.toastErr(success.responseMessage)
      }
      
    }, error => {
      this.service.toastErr("Something went wrong")
    })
  }

  

}
