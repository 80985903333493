import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/providers/mainService';
import { Router } from '../../../../node_modules/@angular/router';

@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.css']
})
export class ContentManagementComponent implements OnInit {
  featureData: any;

  constructor(private route:Router,private service:MainService) { }

  ngOnInit() {
  }
 /*  view(v:string,role:string){
    // localStorage.setItem('control',v)
    // localStorage.setItem('roleView',role)
     this.route.navigate(['/content-view', v, role])
    } */
    // getList() {
    //   this.service.getApi(`admin/comprehensiveListing`, 1).subscribe(success => {
    //     if (success.responseCode == 200) {
    //       this.featureData = success.result
    //     } else {
    //       this.service.toastErr(success.responseMessage)
    //     }
        
    //   }, error => {
    //     this.service.toastErr("Something went wrong")
    //   })
    // }
  
}
