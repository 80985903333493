import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from 'src/app/providers/mainService';

@Component({
  selector: 'app-faq-edit',
  templateUrl: './faq-edit.component.html',
  styleUrls: ['./faq-edit.component.css']
})
export class FaqEditComponent implements OnInit {

  form: FormGroup;
  faqList: any;
  faqId: any;
  ques: any;
  ans : any;
  regex ="^[^\s]+[-a-zA-Z\s]+([-a-zA-Z]+)*$"
  constructor(private activatedroute: ActivatedRoute,private router : Router, public service:MainService, private spinner: NgxSpinnerService) {
    this.activatedroute.queryParams.subscribe((res) => {
      this.faqId = res.itemId;

    });
  }

  ngOnInit() {

    this.form = new FormGroup({
      "question": new FormControl('',[ Validators.required]),
      "answer": new FormControl('',[ Validators.required]),
    });
    this.viewFaq();
  }

// view
viewFaq(){
  let url = 'static/viewFaq'
  const data = {
    "faqId": this.faqId
     }
     this.spinner.show() 
    this.service.postApi(url,data,1).subscribe((res:any) => {
      if(res.response_code == 200){
        this.form.patchValue({
          "question": res.result.question,
          "answer": res.result.answer
        })
        this.service.showSuccess(res.response_message)
           this.spinner.hide();
        // this.router.navigate(['/faq'])
      }
      else{
        this.service.toastErr(res.response_message)
           this.spinner.hide();
      }
    })  
}


  updateFaq(){
    let url = 'static/editFaqs'
    const data = {
   "faqId": this.faqId,
   "question": this.form.value.question,
   "answer" : this.form.value.answer
    }
    this.spinner.show() 
    this.service.postApi(url,data,1).subscribe((res:any) => {
      if(res.response_code == 200){
        this.service.showSuccess(res.response_message)
           this.spinner.hide();
        this.router.navigate(['/help-view'])
      }
      else{
        this.service.toastErr(res.response_message)
           this.spinner.hide();
      }
    })
  }
}
