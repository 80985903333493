import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MainService } from 'src/app/providers/mainService';

@Component({
  selector: 'app-view-video',
  templateUrl: './view-video.component.html',
  styleUrls: ['./view-video.component.css']
})
export class ViewVideoComponent implements OnInit {
  detail: any;

  constructor(  private service: MainService,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.getVideo()
  }
  getVideo() {
    this.spinner.show()
      this.service.getApi('admin/detailVideo', 1).subscribe(success => {
      this.spinner.hide();
      if(success.responseCode==200){
      this.detail=success.result
      } else{
        this.spinner.hide();
        this.service.toastErr(success.responseMessage)

      }
    }, error => {
            this.spinner.hide();
      this.service.toastErr("Something went wrong")
    })
  }

}
