import { Component, OnInit } from '@angular/core';
import { MainService } from '../../providers/mainService';
import { NgModule } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: any;
  loginForm: FormGroup;
  remberMe: any = []

  token: any;
  responseData: any = {};

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    public service: MainService, 
    private spinner: NgxSpinnerService,
    public cookieService: CookieService) { }
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      'email': ['', Validators.compose([Validators.required, Validators.pattern(/^[A-Z0-9_-]+([\.][A-Z0-9_]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,3})+$/i), Validators.maxLength(50)])],
      'pass': ['', Validators.compose([Validators.required, Validators.maxLength(16), Validators.minLength(8)])],
      'remberMe': [false]
    });
    $(function () {
      $('#email,#pass').on('keypress', function (e) {
        console.log("e", e)
        console.log("e.which", e.which)
        if (e.which == 32)
          return true;
      });
    });

    this.loginForm.patchValue({
      email: this.cookieService.get('email'),
      pass: this.cookieService.get('password'),
      remberMe: this.cookieService.check('remberMe')
    })
  }
  login(dataa) {
    this.spinner.show()
  

    let loginData = {
      "email": dataa.email,
      "password": dataa.pass,
      currentDate: Date.now(),
      userType: "SUPERADMIN"

    }
    this.service.postApi('users/login', loginData, 0).subscribe(response => {

      this.responseData = response;
      if (response['responseCode'] == 200) {

        this.service.showSuccess(response['responseMessage'])
        localStorage.setItem('token', this.responseData.token)
        localStorage.setItem('adminId', this.responseData.result._id)
        this.router.navigate(['/dashboard'])
        localStorage.token = response[`token`];
        localStorage.adminId = response[`result`][`_id`];

        if (this.loginForm.controls['remberMe'].value == true) {

          this.cookieService.set('email', this.loginForm.controls['email'].value)
          this.cookieService.set('password', this.loginForm.controls['pass'].value)
          this.cookieService.set('remberMe', this.loginForm.controls['remberMe'].value)

        }
        else {
          this.cookieService.delete('email')
          this.cookieService.delete('password')
          this.cookieService.delete('remberMe')

        }
      
   
      }
      else {
        this.service.toastErr(response['responseMessage'])
      }
  
    this.spinner.hide()
    },
      error => {

    this.spinner.hide()
        this.service.toastErr(`Something went wrong`)

      })

  }
}

