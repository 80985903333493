import { Component, OnInit } from '@angular/core';
import { MainService } from '../../providers/mainService';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '../../../../node_modules/@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-help-edit',
  templateUrl: './help-edit.component.html',
  styleUrls: ['./help-edit.component.css']
})
export class HelptEditComponent implements OnInit {

  viewData: any;
  // about: string = "dbfgsghdsjkhdfsjkbhodsfgjklsfhdjkfdfjksghdfjkdhgkdfksfgajk;fghgdfjkhghjbg;kdshjgjkhgk;dfhjsgkfhjgjhfsgjgjadfjkgjdfklj";
  role: string;
  type: string;
  contentData: string = '';
  staticContentForm: FormGroup
  buttonName: string;
  platView: any;
  platId: any;
  platForm: FormGroup;
  constructor(private service: MainService,
     private router: Router, 
     private spinner: NgxSpinnerService,
         private route: ActivatedRoute) { 
          let url =  window.location.href
          if(url.includes('Platform')){
            this.buttonName = 'plat'
            this.getPlatformData()
            console.log(this.getPlatformData())
          }else {
            this.buttonName = 'allData'
            //this.getTermsData()
          }
         }

  ngOnInit() {
  }

  getTermsData(){
    this.spinner.show()
    this.formValidation()
   this.role = this.route.snapshot.paramMap.get('role');
   this.type = this.route.snapshot.paramMap.get('type');
   this.service.postApi(`terms/getTermsAndConditions`, { role: (this.role).toUpperCase() }, 1)
   .subscribe(response => {
     if (response['responseCode'] == 200) {
       this.viewData = response.result;
       this.contentData = this.viewData[this.type]
       this.staticContentForm.controls['description'].setValue(this.contentData);
     } else {
       this.service.toastErr(response['responseMessage'])
     }
      this.spinner.hide();
   }, error => {
     this.spinner.hide();
     this.service.toastErr('Something Went Wrong')
   })
  }

  platformValidation() {
    this.platForm = new FormGroup({
      description: new FormControl('', [Validators.required])
    })
  }
  formValidation() {
    this.staticContentForm = new FormGroup({
      description: new FormControl('', [Validators.required])
    })
  }

  Update(data) {
    this.viewData[this.type] = data.description
       console.log("apiData", this.viewData)
       this.spinner.show()
    this.service.postApi('terms/updateTermsAndPrivacy', this.viewData, 1).subscribe(response => {
    this.spinner.hide();
      if (response['responseCode'] == 200) {
        this.router.navigate(['/content-management'])
        window.history.back()
      } else {
        this.service.toastErr(response['responseMessage'])
      }

    }, error => {
       this.spinner.hide();
      this.service.toastErr('Something Went Wrong')
    })
  }

  goBack() {
    window.history.back()
  }
//--------------------get platForm --------------//
  getPlatformData(){
    this. platformValidation()
    this.service.post(`static/staticContentList`,1).subscribe(response =>{
      if(response.response_code == 200){
        this.platView = response.result.docs[3].description
        this.platId = response.result.docs[3]._id
        this.platForm.controls['description'].setValue(this.platView);
        console.log(this.platView)
      }

    },error =>{

    })

  }
  UpdatePlat() {
    let data = {
      'staticId' : this.platId,
      'description' : this.platView 
    }
    this.service.postPlatForm('static/editStaticContent',data,1).subscribe(response => {
    this.spinner.hide();
      if (response['response_code'] == 200) {
        this.service.showSuccess(response['response_message'])
        this.getPlatformData()
        this.router.navigate(['/content-management'])
        window.history.back()
      } 
    }, error => {
       this.spinner.hide();
      this.service.toastErr('Something Went Wrong')
    })
  }
}




