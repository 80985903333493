import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from '../../providers/mainService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit {
  
  token: any;
    resetForm: FormGroup;
    

  constructor(public route:ActivatedRoute,public service:MainService,private router: Router) {
    this.resetForm = new FormGroup ({
      'newpassword': new FormControl('',[Validators.required,Validators.minLength(8)]),
      'confirmpassword':new FormControl('',[Validators.required])
    })
   }

  ngOnInit() {
    this.route.params.subscribe(params => {
      console.log('params=>'+JSON.stringify(params));
      this.token = params['token'];
      });
      
  }
  
  /************************ Reset Password Functionality ***************/
  resetFunction(formval){
    let resetBody={
      newPassword:formval.newpassword
    }
    console.log("FormVal---> ",formval.newpassword);
    this.service.postApi(`users/resetPassword?token=${this.token}`,resetBody,0).subscribe(response=>{
      if(response['responseCode']==200) {
        this.service.showSuccess(response['responseMessage'])
        this.router.navigate(['/login'])
  
      }
      else{
        this.service.toastErr(response['responseMessage'])
      }
      },error=>{
        this.service.toastErr(error)
      })
  }

}
