import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from '../../providers/mainService';
import {RatingModule} from "ngx-rating";
import { NgxSpinnerService } from 'ngx-spinner';

declare var $
@Component({
  selector: 'app-venue-management',
  templateUrl: './venue-management.component.html',
  styleUrls: ['./venue-management.component.css']
})
export class VenueManagementComponent implements OnInit {
  competitionForm: FormGroup;
  
  pageNo:any=1
  searchData: any={search:'',status:'' };
  venueData: any;
  venueLength: any;
  total: any;
  limit: any;
  page: any;
  srNo: number;
  realStatus: string = "BLOCK";
  stat: any;
  _id: any;
  status: any;
  constructor(
    private service: MainService,
    private spinner: NgxSpinnerService,

  ) { }

  ngOnInit() {
       this.competitionForm = new FormGroup({
      search: new FormControl('', [Validators.required]),
      // sort: new FormControl('', [Validators.required])
    })

    this.getList()
    
  }

  resetFunction(){
    this.competitionForm.controls['search'].setValue('')
    this.searchData.search=''
    this.getList()
  
  }
  getList(){
    let apiData={
      "page": this.pageNo,
      "limit":10,
      "search":this.searchData.search,
         }
    console.log(apiData)
    this.spinner.show()
   
    this.service.postApi('admin/getVenue',apiData,1).subscribe(success=>{  
   
      this.spinner.hide();
      console.log(success) 
      if(success.responseCode==200){
        this.venueData=success.result.docs
        console.log("venueData",this.venueData)
        this.venueLength=this.venueData.length
              this.total=success.result.total
        this.limit=success.result.limit
        this.page=success.result.page
        this.srNo = (this.pageNo - 1) * this.limit;
      } else{
        this.service.toastErr(success.responseMessage)
      }
    },error=>{
          this.spinner.hide();
      this.service.toastErr("Somethng went wrong")
    })
  }

// delete venue
deleteId:any;
deleteComptition(deleteId){
this.deleteId = deleteId
$('#delete').modal('show');
}

// competition delete
  // competitiondel() {
  //   localStorage.setItem('_id of customer', l);
  //   $('#delete').modal('show');
  // }
  delete() {
    this.spinner.show()
  const reqUrl = `admin/adminDeleteVenue?_id=${this.deleteId}`
  let apiData = {
    "venueId": this.deleteId,
    "userid" : localStorage.getItem('adminId')
       
  }
    // this.service.getApi(`admin/deleteCompetition?_id=${localStorage.getItem('_id of customer')}`, 1)
    this.service.postApi(reqUrl,apiData,1).subscribe(response => {
      if (response['responseCode'] == 200) {
        // localStorage.removeItem('adminId')
        this.service.showSuccess(response['responseMessage'])
        // localStorage.removeItem('_id of customer')
        this.getList()
       
      }
      else {
        this.service.toastErr(response['responseMessage'])
      }
    
      this.spinner.hide()
    }, error => {
    
      this.spinner.hide()
      this.service.toastErr('Something Went Wrong');
    })
    $('#delete').modal('hide');

  }


  filter(data){
    this.searchData=data
    this.pageNo=1
    this.getList()
   }

  changePage(page) {
    this.pageNo = page
    this.getList()
  }

//    change status...

changeStatus(_id, status) {
  console.log("_id", _id)
  this.stat = status
  this._id = _id
  $('#block').modal('show');

}

blockUnblock() {
  this.spinner.show()

  let delData = { '_id': this._id, status: this.stat }
  const url = `admin/blockUnblockVenue?_id=${this._id}`
  console.log("delData", delData)
  $('#block').modal('hide');
  this.service.postApi(url, delData, 1).subscribe(response => {
    console.log("change status", response)
    if (response['responseCode'] == 200) {
      this.service.showSuccess(response[`responseMessage`])

      this.getList();
    }
    else {
      this.service.toastErr(response['responseMessage'])
    }
   
    this.spinner.hide()
  }, error => {
 
    this.spinner.hide()
    this.service.toastErr('Something Went Wrong');
  })

}
userStatus(item) {
  // console.log("item to block and activate",item)

  this.status = item.status;


  if (!this.status) {

    this.realStatus = "ACTIVE";
    // console.log("anurag",this.realStatus)
  }

  let delData = { '_id': item._id, status: this.realStatus }

  console.log("by toggle", delData)
  // this.service.postApi(`users/updateUser`, delData, 1).subscribe(response => {
  //   if (response['responseCode'] == 200) {
  //     // this. search();
  //   }
  //   else {
  //     this.service.toastErr(response['responseMessage'])
  //   }
  // }, error => {
  //   this.service.toastErr('Something Went Wrong');
  // })

}
  

}