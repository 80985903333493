import { Component, OnInit } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from '../../../providers/mainService';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-view-service',
  templateUrl: './view-service.component.html',
  styleUrls: ['./view-service.component.css']
})
export class ViewServiceComponent implements OnInit {
  competitionForm: FormGroup


  pageNo: any = 1
  searchData: any = { search: '', sort: '' };
  serviceData: any;
  serviceLength: any;
  total: any;
  limit: any;
  page: any;
  srNo: number;
  id: any
  constructor(
    private service: MainService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService


  ) {
    this.route.params.subscribe(params => {
      this.id = params.id
      console.log(this.id)
    });
  }

  ngOnInit() {
    this.competitionForm = new FormGroup({
      search: new FormControl('', [Validators.required]),
      sort: new FormControl('')
    })


    this.getList()
  }


  resetFunction() {
    this.competitionForm.controls['search'].setValue('')
    this.searchData.search = ''
    this.getList()

  }
  getList() {
    this.spinner.show()
    let apiData = {
      membershipId: this.id,
      "page": this.pageNo,
      "limit": 10,
      "search": this.searchData.search,
      status: this.searchData.sort

    }
    console.log(apiData)
    this.service.postApi('admin/getServices', apiData, 1).subscribe(success => {
      this.spinner.hide();
      console.log(success)
      if (success.responseCode == 200) {
        this.serviceData = success.result.docs
        console.log("serviceData", this.serviceData)
        this.serviceLength = this.serviceData.length
        this.total = success.result.total
        this.limit = success.result.limit
        this.page = success.result.page
        this.srNo = (this.pageNo - 1) * this.limit;
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
      this.spinner.hide();
      this.service.toastErr("Somethng went wrong")
    })
  }

  filter(data) {
    this.searchData = data
    this.pageNo = 1
    this.getList()
  }

  changePage(page) {
    this.pageNo = page
    this.getList()
  }
}
