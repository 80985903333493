import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../providers/mainService';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-view-service-details',
  templateUrl: './view-service-details.component.html',
  styleUrls: ['./view-service-details.component.css']
})
export class ViewServiceDetailsComponent implements OnInit {
  serviceData: any;
  serviceLength: any;
  srNo: number;
  id: any
  weekOff: any;
  constructor(
    private service: MainService,
    private route: ActivatedRoute,
        private spinner: NgxSpinnerService
  ) {
    this.route.params.subscribe(params => {
      this.id = params
    });
  }

  ngOnInit() {
    this.getList()
  }

  getList() {
    let apiData = {
      "serviceId": this.id.serviceId,
      "membershipId": this.id.membershipId
    }
    this.spinner.show()
    this.service.postApi('admin/particularService', apiData, 1).subscribe(success => {
      this.spinner.hide();
      if (success.responseCode == 200) {
        this.serviceData = success.result[0]
      } else {
        this.service.toastErr(success.responseMessage)
      }
    }, error => {
      this.spinner.hide();
      this.service.toastErr("Somethng went wrong")
    })
  }
  back() {
    window.history.back();
  }
}