import { Component, OnInit } from '@angular/core';
import { MainService } from '../../providers/mainService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-player-management',
  templateUrl: './player-management.component.html',
  styleUrls: ['./player-management.component.css']
})
export class PlayerManagementComponent implements OnInit {

  constructor(
    private service:MainService,
    private router:Router 
  
  ) { }

  ngOnInit() {

    this.getList();
  }


  getList(){
    let apiData={
      "page":1,
      "limit":10,
      "search":""
    }
    // this.service.postApi('api/v1/admin/getCompetition',apiData,1).subscribe(success=>{
    //   console.log("success",success)
    // })
  }

}
