import { Component, OnInit } from '@angular/core';
import { MainService } from '../../providers/mainService';
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-content-management-view',
  templateUrl: './content-management-view.component.html',
  styleUrls: ['./content-management-view.component.css']
})
export class ContentManagementViewComponent implements OnInit {
  controls
  viewData: any;
  role: string;
  type: string = ''
  currId: string = '';
  platView: any;
  platId: any;
  buttonName: any;
  title: any;
  arrayindex: any;
  constructor(private service: MainService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute) {
      let url =  window.location.href
      if(url.includes('Platform')){
        this.buttonName = 'plat'
        this.getPlatformData()
        console.log(this.getPlatformData())
      }else {
        this.buttonName = 'allData'
        this.getTermsData()
      }
     }

  ngOnInit() {
  }
  getTermsData(){
    this.spinner.show()
    this.role = this.route.snapshot.paramMap.get('role');
    this.type = this.route.snapshot.paramMap.get('type');
    this.service.postApi(`terms/getTermsAndConditions`, { role: (this.role).toUpperCase() }, 1)
      .subscribe(response => {
        this.spinner.hide();
        if (response['responseCode'] == 200) {
          console.log("response data>>", response)
          this.viewData = response.result[this.type];
          this.viewData = this.sanitizer.bypassSecurityTrustHtml(this.viewData)
        }
        else {
          this.service.toastErr(response['responseMessage'])
        }
      }, error => {
        this.spinner.hide();
        this.service.toastErr('Something Went Wrong')
      })
  }

  goBack() {
    window.history.back()
  }
  // 
  getPlatformData(){
    this.service.post(`static/staticContentList`,1).subscribe(response =>{
      if(response.response_code == 200){
        
        this.platView = response.result.docs[3].description,
        this.title=response.result.docs[3].title
        console.log(this.platView)
        this.platId = response.result.docs[3]._id
      }

    },error =>{

    })

  }




}
