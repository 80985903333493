import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';



@Injectable()
export class MainService {
    token: any;
    _id: any;
    adminId: any;
    // baseUrl =  'http://182.72.203.245:1808/api/v1/';
   // baseUrl = 'http://localhost:1808/api/v1/'; 

//    baseUrl='http://localhost:1808/api/v1/' 
    // baseUrl: String = 'https://backend.digixvsx.com/api/v1/';
    // baseUrl: String = 'http://172.16.11.134:1808/api/v1/'; //rajat sir ip,
    // baseUrl:string='http://172.16.1.238:1808/api/v1/' //moin sir ip

    baseUrl: String = 'https://sportsweb-backend.mobiloitte.com/api/v1/';
    //staging

    constructor(private http:HttpClient,private toastr: ToastrService) { }

  
   
//    >>>>staging URL>>
//  baseUrl = 'http://mean.mobiloitte.com:1414/api/v1/';
// baseUrl='http://172.16.6.254:1414/api/v1/' //mansi
//baseUrl = window.location.origin+'/api/v1/';
    typeLogin: string;
  postApi(url, data, isHeader) :Observable<any> {
        if(isHeader==0){
            var httpOptions;
            httpOptions = {
                headers: new HttpHeaders({ 
                    "Content-Type": "application/json", 
                })
              }
            return this.http.post((this.baseUrl+ url),data,httpOptions)
        }  
        else if(isHeader==1){
            var httpOptions;
            httpOptions = {
                headers: new HttpHeaders({
                    "Content-Type": "application/json", 
                    "token": localStorage.getItem("token"),
                     "userId": localStorage.getItem("adminId"),
                     })
              }
              return this.http.post((this.baseUrl + url), data, httpOptions)
        }
    }
//....................................VideoUpload Api......FormData.........

postApiUpload(url, data, isHeader) :Observable<any> {
    if(isHeader==0){
        var httpOptions;
        httpOptions = {
            headers: new HttpHeaders({ 
                // "Content-Type": "multipart/form-data", 
            })
          }
        return this.http.post((this.baseUrl+ url),data,httpOptions)
    } else if(isHeader==1){
        var httpOptions;
        httpOptions = {
            headers: new HttpHeaders({
                // "Content-Type": "multipart/form-data", 
                "token": localStorage.getItem("token"),
                 "userId": localStorage.getItem("adminId"),
                 })
          }
          return this.http.post((this.baseUrl + url), data, httpOptions)
    }
}
//.................................End VideoUpload .................................................





//....................................Message Api......FormData.........

postApiMessage(url, data, isHeader) :Observable<any> {
    if(isHeader==0){
        var httpOptions;
        httpOptions = {
            headers: new HttpHeaders({ 
                // "Content-Type": "multipart/form-data", 
            })
          }
        return this.http.post((this.baseUrl+ url),data,httpOptions)
    } else if(isHeader==1){
        var httpOptions;
        httpOptions = {
            headers: new HttpHeaders({
                // "Content-Type": "multipart/form-data", 
                "token": localStorage.getItem("token"),
                 "userId": localStorage.getItem("adminId"),
                 })
          }
          return this.http.post((this.baseUrl + url), data, httpOptions)
    }
}
//.................................End message .................................................
      getApi(url, isHeader) :Observable<any> {
        if(isHeader==0){
            var httpOptions;
            httpOptions = {
                headers: new HttpHeaders({ 
                    "Content-Type": "application/json"
                })
              }
            return this.http.get((this.baseUrl+ url),httpOptions)
        }
        else if(isHeader==1){
            var httpOptions;
            httpOptions = {
                headers: new HttpHeaders({
                    "Content-Type": "application/json", 
                    "token": localStorage.getItem("token"),
                     "userId": localStorage.getItem("adminId"),
                     })
              }
              return this.http.get((this.baseUrl + url), httpOptions)
        }
      }
//-----------------------token in header for post API -----------------------//
post(url,isHeader) :Observable<any> {
    if(isHeader==0){
        var httpOptions;
        httpOptions = {
            headers: new HttpHeaders({ 
                "Content-Type": "application/json"
            })
          }
        return this.http.post((this.baseUrl+ url),httpOptions)
    }
    else if(isHeader==1){
        var httpOptions;
        httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json", 
                "token": localStorage.getItem("token"),
                 })
          }
          return this.http.post((this.baseUrl + url), httpOptions)
    }
  }
  postPlatForm(url, data,isHeader): Observable<any>{
    if(isHeader==0){
        var httpOptions;
        httpOptions = {
            headers: new HttpHeaders({ 
                "Content-Type": "application/json"
            })
          }
        return this.http.post((this.baseUrl+ url),data,httpOptions)
    }
    else if(isHeader==1){
        var httpOptions;
        httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json", 
                "token": localStorage.getItem("token"),
                 })
          }
          return this.http.post((this.baseUrl + url),data, httpOptions)
    }
  }
     //............... Toster function ................................
      showSuccess(msg) {
        this.toastr.success(msg);
      }
      toastErr(msg) {
		this.toastr.error(msg)
    }
    //................. End Toster Function............................
}